import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCart } from '@/domain/retail/cart/cart-hooks'
import { Button } from '@/shared/components/ui/button'
import { Card } from '@/shared/components/ui/card'
import { Input } from '@/shared/components/ui/input'

interface ShippingInfo {
  firstName: string
  lastName: string
  email: string
  address: string
  city: string
  state: string
  zipCode: string
}

const initialShippingInfo: ShippingInfo = {
  firstName: '',
  lastName: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
}

export const CheckoutPage: React.FC = () => {
  const { state: cart, clearCart } = useCart()
  const navigate = useNavigate()
  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>(initialShippingInfo)
  const [paymentMethod, setPaymentMethod] = useState<'credit' | 'debit'>('credit')
  const [loading, setLoading] = useState(false)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setShippingInfo(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)

    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000))
      clearCart()
      navigate('/order-success')
    } catch (error) {
      console.error('Checkout error:', error)
    } finally {
      setLoading(false)
    }
  }

  if (cart.items.length === 0) {
    return (
      <div className='container mx-auto p-4'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <h2 className='text-xl font-semibold text-gray-900 dark:text-white mb-4'>
            Your cart is empty
          </h2>
          <Button
            className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            onClick={() => navigate('/')}
          >
            Continue Shopping
          </Button>
        </Card>
      </div>
    )
  }

  return (
    <div className='container mx-auto p-4'>
      <h1 className='text-2xl font-bold mb-6 text-gray-900 dark:text-white'>Checkout</h1>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
        <div className='space-y-6'>
          {/* Shipping Information */}
          <Card className='p-6 bg-white dark:bg-gray-800'>
            <h2 className='text-xl font-semibold text-gray-900 dark:text-white mb-4'>
              Shipping Information
            </h2>
            <form className='space-y-4'>
              <div className='grid grid-cols-2 gap-4'>
                <Input
                  name='firstName'
                  placeholder='First Name'
                  value={shippingInfo.firstName}
                  onChange={handleInputChange}
                  className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
                />
                <Input
                  name='lastName'
                  placeholder='Last Name'
                  value={shippingInfo.lastName}
                  onChange={handleInputChange}
                  className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
                />
              </div>
              <Input
                name='email'
                type='email'
                placeholder='Email'
                value={shippingInfo.email}
                onChange={handleInputChange}
                className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
              />
              <Input
                name='address'
                placeholder='Address'
                value={shippingInfo.address}
                onChange={handleInputChange}
                className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
              />
              <div className='grid grid-cols-2 gap-4'>
                <Input
                  name='city'
                  placeholder='City'
                  value={shippingInfo.city}
                  onChange={handleInputChange}
                  className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
                />
                <Input
                  name='state'
                  placeholder='State'
                  value={shippingInfo.state}
                  onChange={handleInputChange}
                  className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
                />
              </div>
              <Input
                name='zipCode'
                placeholder='ZIP Code'
                value={shippingInfo.zipCode}
                onChange={handleInputChange}
                className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
              />
            </form>
          </Card>

          {/* Payment Method */}
          <Card className='p-6 bg-white dark:bg-gray-800'>
            <h2 className='text-xl font-semibold text-gray-900 dark:text-white mb-4'>
              Payment Method
            </h2>
            <div className='space-y-4'>
              <div className='flex items-center space-x-4'>
                <button
                  className={`flex-1 p-4 rounded-lg border ${
                    paymentMethod === 'credit'
                      ? 'border-emerald-600 bg-emerald-50 dark:bg-emerald-900/20'
                      : 'border-gray-200 dark:border-gray-700'
                  }`}
                  onClick={() => setPaymentMethod('credit')}
                >
                  <span className='text-gray-900 dark:text-white font-medium'>Credit Card</span>
                </button>
                <button
                  className={`flex-1 p-4 rounded-lg border ${
                    paymentMethod === 'debit'
                      ? 'border-emerald-600 bg-emerald-50 dark:bg-emerald-900/20'
                      : 'border-gray-200 dark:border-gray-700'
                  }`}
                  onClick={() => setPaymentMethod('debit')}
                >
                  <span className='text-gray-900 dark:text-white font-medium'>Debit Card</span>
                </button>
              </div>
              <Input
                placeholder='Card Number'
                className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
              />
              <div className='grid grid-cols-2 gap-4'>
                <Input
                  placeholder='MM/YY'
                  className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
                />
                <Input
                  placeholder='CVV'
                  className='bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white'
                />
              </div>
            </div>
          </Card>
        </div>

        {/* Order Summary */}
        <div>
          <Card className='p-6 bg-white dark:bg-gray-800 sticky top-4'>
            <h2 className='text-xl font-semibold text-gray-900 dark:text-white mb-4'>
              Order Summary
            </h2>
            <div className='space-y-4'>
              {cart.items.map(item => (
                <div key={item.product.id} className='flex justify-between items-center'>
                  <div className='flex items-center space-x-4'>
                    <span className='text-gray-800 dark:text-gray-200'>{item.product.name}</span>
                    <span className='text-gray-600 dark:text-gray-400'>x{item.quantity}</span>
                  </div>
                  <span className='text-gray-900 dark:text-white font-medium'>
                    ${(item.product.price * item.quantity).toFixed(2)}
                  </span>
                </div>
              ))}
              <div className='border-t border-gray-200 dark:border-gray-700 pt-4 space-y-2'>
                <div className='flex justify-between'>
                  <span className='text-gray-600 dark:text-gray-400'>Subtotal</span>
                  <span className='text-gray-900 dark:text-white'>
                    ${cart.totalPrice.toFixed(2)}
                  </span>
                </div>
                <div className='flex justify-between'>
                  <span className='text-gray-600 dark:text-gray-400'>Shipping</span>
                  <span className='text-gray-900 dark:text-white'>$5.00</span>
                </div>
                <div className='flex justify-between font-bold pt-2 border-t border-gray-200 dark:border-gray-700'>
                  <span className='text-gray-900 dark:text-white'>Total</span>
                  <span className='text-gray-900 dark:text-white'>
                    ${(cart.totalPrice + 5).toFixed(2)}
                  </span>
                </div>
              </div>
            </div>

            <Button
              className='w-full mt-6 bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Place Order'}
            </Button>
          </Card>
        </div>
      </div>
    </div>
  )
}
