import React, { useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import { useReturnProcess } from './use-cases'
import type { ReturnMethod } from '@/domain/retail/returns/return-types'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import OnIcon from '@/assets/logos/onIcon.svg'
import OnSpinner from '@/assets/animated/OnSpinner'
import { Check } from '@/assets/icons/Icons'

export const ReturnConfirmationPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId') || ''
  const method = searchParams.get('method') as ReturnMethod
  const value = Number(searchParams.get('value') || 0)
  const navigate = useNavigate()
  const { isProcessing, error, initiateReturn } = useReturnProcess()
  const [confirmed, setConfirmed] = useState(false)
  const [ready, setReady] = useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  async function confirmReturn() {
    try {
      setConfirmed(true)
      const request = await initiateReturn(productId, method, value)
      setTimeout(() => {
        navigate(`/return/success?requestId=${request.id}&method=${method}&value=${value}`)
      }, 1000)
    } catch (err) {
      setConfirmed(false)
    }
  }

  if (confirmed || isProcessing) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <div className='text-center'>
            <div className='animate-pulse text-emerald-600 dark:text-emerald-400 text-lg font-medium'>
              Processing your return...
            </div>
          </div>
        </Card>
      </div>
    )
  }

  if (error) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <h2 className='text-2xl font-bold mb-4 text-red-600 dark:text-red-400'>Error</h2>
          <p className='text-gray-800 dark:text-gray-200 mb-6'>{error}</p>
          <div className='flex justify-end space-x-4'>
            <Button
              variant='outline'
              onClick={() => navigate('/return')}
              className='text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600'
            >
              Start Over
            </Button>
            <Button
              onClick={() => setConfirmed(false)}
              className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            >
              Try Again
            </Button>
          </div>
        </Card>
      </div>
    )
  }

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      {ready ? (
        <>
          <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-between'>
            <div className='flex items-center gap-4'>
              <img
                src={OnIcon}
                alt='ON Logo'
                className='w-[48px] h-[48px]'
                onClick={() => navigate('/')}
              />
            </div>
            <KioskButton>
              <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
              <div className='text-lg font-light'>Hey, Phil</div>
            </KioskButton>
          </div>
          {/* <img src={OnLogo} alt='On Logo' className='w-16 h-16 mb-4' /> */}
          <div className='w-full flex justify-center pb-2'>
            <Check className='w-12 h-12' />
          </div>
          <h1 className='text-[2.5rem] font-medium font-manrope text-white'>Return Successful!</h1>
          <p className='text-lg text-text-secondary font-light w-full text-center border-b border-white/50 pb-12'>
            Thank you for your return. Your{' '}
            {method === 'points' ? 'loyalty points have' : 'store credit has'} been issued.
          </p>
          <div className='bg-black text-wite rounded-xl border border-white sm:min-w-[500px] min-w-[350px] py-6 px-6 space-y-4 mb-8 text-text-primary my-6'>
            <h3 className='text-xl font-bold mb-4'>Return Summary</h3>
            <div className='space-y-3'>
              <div className='flex justify-between items-center'>
                <span className='dark:text-gray-300'>Return ID:</span>
                <span className='font-semibold'>fbag2iqwq8em4n1xm9a</span>
              </div>
              <div className='flex justify-between items-center'>
                <span className='dark:text-gray-300'>Return Method:</span>
                <span className='font-semibold'>
                  {method === 'storeCredit' ? 'Store Credit' : 'Loyalty Points'}
                </span>
              </div>
              <div className='flex justify-between items-center'>
                <span className='dark:text-gray-300'>Value:</span>
                <span className='font-bold text-lg'>
                  {method === 'storeCredit' ? `$25.00` : `450 points`}
                </span>
              </div>
            </div>
          </div>
          <KioskButton
            className='bg-yellow-500 text-black border-none py-3 px-6 hover:bg-yellow-400'
            onClick={() => navigate(`/`)}
          >
            Return Home
          </KioskButton>
        </>
      ) : (
        <OnSpinner className='w-[200px] h-[200px]' />
      )}
    </div>

    // <div className='max-w-2xl mx-auto px-4 py-8'>
    //   <Card className='p-6 bg-white dark:bg-gray-800'>
    //     <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-50'>Confirm Return</h2>

    //     <div className='mb-8'>
    //       <div className='p-4 bg-emerald-50 dark:bg-emerald-900 rounded-lg'>
    //         <p className='text-emerald-800 dark:text-emerald-100 text-lg'>
    //           You will receive{' '}
    //           {method === 'storeCredit' ? (
    //             <span className='font-bold'>${value.toFixed(2)} in store credit</span>
    //           ) : (
    //             <span className='font-bold'>{value.toLocaleString()} loyalty points</span>
    //           )}{' '}
    //           for this return.
    //         </p>
    //       </div>
    //     </div>

    //     <div className='space-y-4'>
    //       <p className='text-gray-900 dark:text-gray-100 text-lg font-medium'>
    //         By proceeding, you confirm that:
    //       </p>
    //       <ul className='list-disc list-inside space-y-2 text-gray-800 dark:text-gray-200'>
    //         <li>The item is in its original condition</li>
    //         <li>All original packaging and accessories are included</li>
    //         <li>You understand this return is final</li>
    //       </ul>
    //     </div>

    //     <div className='flex justify-end space-x-4 mt-8'>
    //       <Button
    //         variant='outline'
    //         onClick={() => navigate(-1)}
    //         className='text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'
    //       >
    //         Go Back
    //       </Button>
    //       <Button
    //         onClick={confirmReturn}
    //         className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
    //       >
    //         Confirm Return
    //       </Button>
    //     </div>
    //   </Card>
    // </div>
  )
}
