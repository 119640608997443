import { useState, useEffect, useRef } from 'react'

export function useAudioAnalyzer() {
  const [intensity, setIntensity] = useState(0)
  const analyzerRef = useRef<AnalyserNode | null>(null)
  const dataArrayRef = useRef<Uint8Array | null>(null)
  const animationFrameRef = useRef<number>()

  useEffect(() => {
    let audioContext: AudioContext
    let mediaStream: MediaStream

    const initializeAnalyzer = async () => {
      try {
        mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true })
        audioContext = new AudioContext()

        const source = audioContext.createMediaStreamSource(mediaStream)
        const analyzer = audioContext.createAnalyser()
        analyzer.fftSize = 256
        source.connect(analyzer)

        analyzerRef.current = analyzer
        dataArrayRef.current = new Uint8Array(analyzer.frequencyBinCount)

        const analyze = () => {
          if (!analyzerRef.current || !dataArrayRef.current) return

          analyzerRef.current.getByteFrequencyData(dataArrayRef.current)
          const average = dataArrayRef.current.reduce((a, b) => a + b) / dataArrayRef.current.length
          setIntensity(average / 50) // Normalize to 0-1 range

          animationFrameRef.current = requestAnimationFrame(analyze)
        }

        analyze()
      } catch (error) {
        console.error('Error accessing microphone:', error)
      }
    }

    initializeAnalyzer()

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current)
      }
      if (mediaStream) {
        mediaStream.getTracks().forEach(track => track.stop())
      }
    }
  }, [])

  return intensity
}
