import React, { Component } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AIAction } from '../../../domain/retail/use-cases/conversations/types'
import { useVoiceProcessor } from '../../../domain/ai/voice/hooks/useVoiceProcessor'
import { Close, MicOff, Microphone } from '@/assets/icons/Icons'
import { AnimatePresence, motion } from 'framer-motion'
import { VoiceWaveform } from '@/shared/components/animations/voice-waveform'
import { useAudioAnalyzer } from '@/shared/hooks/use-audio-analyzer'

interface VoiceProcessorComponentProps {
  onVoiceCommand: (text: string, action: AIAction) => void
}

function VoiceProcessorComponent({ onVoiceCommand }: VoiceProcessorComponentProps) {
  const { isListening, transcript, lastCommand, startListening, stopListening } =
    useVoiceProcessor()
  const [isInitialized, setIsInitialized] = React.useState(false)
  const [localTranscript, setLocalTranscript] = React.useState('')
  const intensity = useAudioAnalyzer()
  const [micDisabled, setMicDisabled] = React.useState(false)

  const location = useLocation()

  // Paths where microphone should be disabled
  React.useEffect(() => {
    const MIC_DISABLED_PATHS = ['/return/scan']
    const currentPath = location.pathname
    const shouldDisableMic = MIC_DISABLED_PATHS.some(path => currentPath === path)
    setMicDisabled(shouldDisableMic)

    if (shouldDisableMic && isListening) {
      stopListening()
    }
  }, [isListening, stopListening, location.pathname])

  // Update local transcript when transcript changes
  React.useEffect(() => {
    if (transcript) {
      console.log('Transcript updated:', transcript)
      setLocalTranscript(transcript)
    }
  }, [transcript])

  React.useEffect(() => {
    if (lastCommand && lastCommand.text) {
      const actionType = mapVoiceCommandToAIAction(lastCommand.type)
      if (actionType) {
        onVoiceCommand(lastCommand.text, {
          type: actionType,
          payload: {},
          confidence: lastCommand.confidence,
          timestamp: Date.now(),
        })
      }
    }
  }, [lastCommand, onVoiceCommand])

  React.useEffect(() => {
    if (!isInitialized) {
      console.log('VoiceProcessor: Auto-starting voice recognition...')
      startListening().catch((error: Error) => {
        console.error('VoiceProcessor: Failed to auto-start voice recognition:', error)
      })
      setIsInitialized(true)
    }
    return () => {
      if (isListening) {
        stopListening()
      }
    }
  }, [startListening, stopListening, isInitialized, isListening])

  return (
    <div className='fixed bottom-0 w-screen p-8 flex justify-center z-50 pointer-events-none'>
      <motion.div
        className={`bg-white text-black cursor-pointer py-2 px-5 pointer-events-auto flex items-center gap-2 ${
          micDisabled ? 'opacity-50' : ''
        }`}
        style={{
          borderRadius: '9999px',
        }}
        layout
      >
        <AnimatePresence>
          {/* <div className='flex items-center gap-2'>
          <div
            className={`w-3 h-3 rounded-full ${
              isListening ? 'bg-red-500 animate-pulse' : 'bg-gray-500'
            }`}
          />
          <span>{isListening ? 'Listening...' : 'Click to start'}</span>
        </div> */}

          <motion.div
            layout='position'
            onClick={() => {
              if (isListening) {
                stopListening()
                setLocalTranscript('') // Clear transcript when stopping
              } else {
                startListening().catch(console.error)
              }
            }}
          >
            {!micDisabled ? <Microphone className='w-8 h-8' /> : <MicOff className='w-8 h-8' />}
          </motion.div>

          {isListening && !micDisabled && (
            <motion.div
              layout='position'
              className='w-8 sm:w-32' // Adjust width as needed
            >
              <VoiceWaveform
                isActive={isListening}
                intensity={intensity}
                color='#000' // Or any color that matches your design
                className='h-8 overflow-hidden' // Match height with icons
              />
            </motion.div>
          )}

          {isListening && !micDisabled && (
            <motion.div layout='position' onClick={() => stopListening()}>
              <Close className='w-8 h-8' />
            </motion.div>
          )}
          {/* {localTranscript && <div className='mt-2 text-sm opacity-75'>Heard: {localTranscript}</div>} */}
        </AnimatePresence>
      </motion.div>
    </div>
  )
}

function mapVoiceCommandToAIAction(commandType: string): AIAction['type'] | undefined {
  const commandMap: Record<string, AIAction['type']> = {
    search: 'DISPLAY_PRODUCTS',
    add: 'ADD_TO_CART',
    remove: 'REMOVE_FROM_CART',
    update: 'UPDATE_QUANTITY',
    return: 'START_RETURN',
    repair: 'START_REPAIR',
    refill: 'START_REFILL',
    navigate: 'NAVIGATION',
    help: 'SYSTEM_FEEDBACK',
  }
  return commandMap[commandType.toLowerCase()]
}

interface VoiceEnabledLayoutProps {
  children: React.ReactNode
  onVoiceCommand: (text: string, action: AIAction) => void
}

export function VoiceEnabledLayout({ children, onVoiceCommand }: VoiceEnabledLayoutProps) {
  return (
    <>
      {children}
      <VoiceProcessorComponent onVoiceCommand={onVoiceCommand} />
    </>
  )
}
