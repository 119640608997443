import React, { createContext, useContext, useEffect, useState } from 'react'
import type { CustomerProfile } from '../../users/types/customer-profile'
import type { CustomerService } from '../userService'
import { DEMO_USER_ID } from '../mockData'

interface CustomerContextType {
  profile: CustomerProfile | null
  isLoading: boolean
  error: Error | null
  updateProfile: (updates: Partial<CustomerProfile>) => Promise<void>
}

const CustomerContext = createContext<CustomerContextType | undefined>(undefined)

interface CustomerProviderProps {
  children: React.ReactNode
  customerService: CustomerService
}

export function CustomerProvider({ children, customerService }: CustomerProviderProps) {
  const [profile, setProfile] = useState<CustomerProfile | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    async function loadProfile() {
      try {
        // Always load the demo user profile
        const userProfile = await customerService.getCustomerProfile(DEMO_USER_ID)
        setProfile(userProfile)
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to load profile'))
      } finally {
        setIsLoading(false)
      }
    }

    loadProfile()
  }, [customerService])

  const updateProfile = async (updates: Partial<CustomerProfile>) => {
    try {
      const updatedProfile = await customerService.updateCustomerProfile(DEMO_USER_ID, updates)
      setProfile(updatedProfile)
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to update profile'))
      throw err
    }
  }

  return (
    <CustomerContext.Provider
      value={{
        profile,
        isLoading,
        error,
        updateProfile,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

export function useCustomer() {
  const context = useContext(CustomerContext)
  if (!context) {
    throw new Error('useCustomer must be used within a CustomerProvider')
  }
  return context
}
