import { Product } from './product-types'

type ProductFilter = Partial<{
  [K in keyof Product]: Product[K]
}>

class ProductService {
  private products: Product[] = [
    {
      id: '1',
      sku: 'ON-CLOUDX-001',
      name: 'Cloud X',
      brand: 'On',
      description: 'Lightweight performance running shoe perfect for mixed-activity workouts',
      price: 139.99,
      image: '/images/products/on/cloudx.png',
      inStock: true,
      category: 'Running',
      sizes: ['7', '8', '9', '10', '11', '12'],
    },
    {
      id: '2',
      sku: 'ON-CLOUDFLOW-001',
      name: 'Cloudflow',
      brand: 'On',
      description: 'Competition-level road running shoe with responsive cushioning',
      price: 159.99,
      image: '/images/products/on/cloudflow.png',
      inStock: true,
      category: 'Running',
      sizes: ['8', '9', '10', '11'],
    },
    {
      id: '3',
      sku: 'ON-CLOUDMONSTER-001',
      name: 'Cloudmonster',
      brand: 'On',
      description: 'Maximum cushioning shoe for long-distance comfort',
      price: 169.99,
      image: '/images/products/on/cloudmonster.png',
      inStock: true,
      category: 'Running',
      sizes: ['8', '9', '10', '11', '12'],
    },
    {
      id: '4',
      sku: 'ON-CLOUDULTRA-001',
      name: 'Cloudultra',
      brand: 'On',
      description: 'Trail running shoe designed for ultra-distance performance',
      price: 179.99,
      image: '/images/products/on/cloudultra.png',
      inStock: true,
      category: 'Trail Running',
      sizes: ['7', '8', '9', '10', '11', '12'],
    },
    {
      id: '5',
      sku: 'ON-CLOUDSTRATUS-001',
      name: 'Cloudstratus',
      brand: 'On',
      description: 'Maximum cushioning with double CloudTec® layers',
      price: 169.99,
      image: '/images/products/on/cloudstratus.png',
      inStock: true,
      category: 'Running',
      sizes: ['8', '9', '10', '11', '12'],
    },
    {
      id: '6',
      sku: 'ON-CLOUDBOOM-001',
      name: 'Cloudboom Echo',
      brand: 'On',
      description: 'Competition racing shoe with carbon fiber plate',
      price: 199.99,
      image: '/images/products/on/cloudboomecho.png',
      inStock: true,
      category: 'Racing',
      sizes: ['7', '8', '9', '10', '11', '12'],
    },
  ]

  async getProductDetails(id: string): Promise<Product> {
    const product = await this.getProduct(id)
    if (!product) {
      throw new Error(`Product not found: ${id}`)
    }
    return product
  }

  async getProduct(id: string): Promise<Product | undefined> {
    return this.products.find(p => p.id === id)
  }

  async listProducts(): Promise<Product[]> {
    return [...this.products]
  }

  async searchProducts(query: string): Promise<Product[]> {
    const lowercaseQuery = query.toLowerCase()
    return this.products.filter(
      p =>
        p.name.toLowerCase().includes(lowercaseQuery) ||
        p.description.toLowerCase().includes(lowercaseQuery)
    )
  }

  async filterProducts(filters: ProductFilter): Promise<Product[]> {
    if (Object.keys(filters).length === 0) {
      return this.listProducts()
    }

    return this.products.filter(product => {
      return Object.entries(filters).every(([key, value]) => {
        if (value === undefined || value === '') return true
        return product[key as keyof Product] === value
      })
    })
  }
}

export const productService = new ProductService()
