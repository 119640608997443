import type { VoiceProcessorConfig } from '../types/voice'
import type {
  SpeechRecognition,
  SpeechRecognitionConstructor,
  SpeechRecognitionEvent,
  SpeechRecognitionErrorEvent,
} from '../types/web-speech'

declare global {
  interface Window {
    SpeechRecognition: SpeechRecognitionConstructor
    webkitSpeechRecognition: SpeechRecognitionConstructor
  }
}

const SpeechRecognitionImpl = window.SpeechRecognition || window.webkitSpeechRecognition

export class EnhancedSpeechRecognition {
  private recognition: SpeechRecognition | null = null
  private isListening = false
  private isSpeaking = false
  private shouldRestart = true
  private config: VoiceProcessorConfig = {
    language: 'en-US',
    continuous: true,
    interimResults: true,
    maxAlternatives: 1,
  }

  constructor(config?: Partial<VoiceProcessorConfig>) {
    console.log('Initializing EnhancedSpeechRecognition')
    if (!SpeechRecognitionImpl) {
      throw new Error('Speech recognition is not supported in this browser')
    }

    this.config = {
      ...this.config,
      ...config,
    }
  }

  setupRecognition(callbacks: {
    onStart?: () => void
    onEnd?: () => void
    onError?: (error: Error) => void
    onResult?: (results: SpeechRecognitionResultList) => void
    config?: Partial<VoiceProcessorConfig>
  }): void {
    console.log('Setting up recognition')
    
    // Create a new instance
    this.recognition = new SpeechRecognitionImpl()

    // Basic configuration
    this.recognition.lang = 'en-US'
    this.recognition.continuous = true
    this.recognition.interimResults = true

    console.log('Recognition configuration:', {
      lang: this.recognition.lang,
      continuous: this.recognition.continuous,
      interimResults: this.recognition.interimResults
    })

    this.recognition.onstart = () => {
      console.log('Recognition started')
      this.isListening = true
      callbacks.onStart?.()
    }

    this.recognition.onend = () => {
      console.log('Recognition ended')
      this.isListening = false
      callbacks.onEnd?.()
      
      // Only restart if we should
      if (this.shouldRestart && !this.isSpeaking) {
        console.log('Will restart recognition')
        setTimeout(() => {
          if (this.shouldRestart && !this.isListening) {
            this.start()
          }
        }, 100)
      }
    }

    this.recognition.onerror = (event) => {
      console.error('Recognition error:', event.error)
      callbacks.onError?.(new Error(event.error))
      this.isListening = false
      
      // Don't restart on error
      this.shouldRestart = false
    }

    this.recognition.onresult = (event: SpeechRecognitionEvent) => {
      console.log('Raw recognition result:', event)
      
      // Process all results in the current event
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const result = event.results[i]
        if (result && result[0]) {
          console.log('Processing result:', {
            transcript: result[0].transcript,
            confidence: result[0].confidence,
            isFinal: result.isFinal
          })
        }
      }
      
      callbacks.onResult?.(event.results)
    }
  }

  start(): void {
    console.log('Starting recognition...', { isListening: this.isListening })
    if (this.isListening) {
      console.log('Recognition already running')
      return
    }

    this.shouldRestart = true

    if (!this.recognition) {
      this.setupRecognition({
        onStart: () => console.log('Default start callback'),
        onEnd: () => console.log('Default end callback'),
        onError: (error) => console.error('Default error callback:', error),
        onResult: (results) => {
          console.log('Default result callback:', Array.from(results).map(result => ({
            transcript: result[0]?.transcript,
            confidence: result[0]?.confidence,
            isFinal: result.isFinal
          })))
        }
      })
    }

    try {
      this.recognition?.start()
    } catch (error) {
      console.error('Error starting recognition:', error)
      this.isListening = false
      this.shouldRestart = false
    }
  }

  stop(): void {
    console.log('Stopping recognition...')
    if (!this.isListening) {
      console.log('Recognition not running')
      return
    }

    try {
      this.recognition?.stop()
      this.isListening = false
    } catch (error) {
      console.error('Error stopping recognition:', error)
    }
  }

  setSpeaking(speaking: boolean): void {
    this.isSpeaking = speaking
  }
}

export const enhancedSpeechRecognition = new EnhancedSpeechRecognition()
