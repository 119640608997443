import { audioManager } from './audio-manager'

class OpenAIAudio {
  private apiKey: string
  private baseUrl = 'https://api.openai.com/v1/audio/speech'
  private voice = 'alloy' // OpenAI's default voice

  constructor() {
    this.apiKey = import.meta.env.VITE_OPENAI_API_KEY

    if (!this.apiKey) {
      console.warn('OpenAI API key is not configured. Please check your .env file.')
    }
  }

  async speak(text: string): Promise<void> {
    if (!this.apiKey) {
      throw new Error('OpenAI API key is not configured')
    }

    try {
      const response = await fetch(this.baseUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.apiKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'tts-1',
          input: text,
          voice: this.voice,
        }),
      })

      if (!response.ok) {
        throw new Error(`OpenAI API error: ${response.statusText}`)
      }

      const audioBlob = await response.blob()
      const audioUrl = URL.createObjectURL(audioBlob)
      await audioManager.play(audioUrl)

      // Clean up the URL after playback
      URL.revokeObjectURL(audioUrl)
    } catch (error) {
      console.error('Error generating or playing speech:', error)
      throw error
    }
  }

  setVoice(voice: 'alloy' | 'echo' | 'fable' | 'onyx' | 'nova' | 'shimmer') {
    this.voice = voice
  }

  stop() {
    audioManager.stop()
  }
}

export const openAiAudio = new OpenAIAudio()
