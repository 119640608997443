export type Brand = {
  id: string
  name: string
  logo: string
}

export const brands: Brand[] = [
  {
    id: 'on',
    name: 'On Running',
    logo: '/images/brands/on-logo.png',
  },
  {
    id: 'loreal',
    name: "L'Oréal",
    logo: '/images/brands/loreal-logo.png',
  },
]

export type ProductType = 'shoe' | 'cream' | 'serum' | 'lotion'

export type Product = {
  id: string
  brandId: string
  name: string
  type: ProductType
  image: string
  description: string
  price: number
  repairEligible: boolean
  refillEligible: boolean
  returnEligible: boolean
}

export const products: Product[] = [
  // On Running Products
  {
    id: 'cloudmonster',
    brandId: 'on',
    name: 'Cloudmonster',
    type: 'shoe',
    image: 'https://placehold.co/400x400?text=Cloudmonster', // Temporary placeholder
    description: 'Maximum cushioning meets explosive take-offs. Featuring the most CloudTec® ever.',
    price: 169.99,
    repairEligible: true,
    refillEligible: false,
    returnEligible: true,
  },
  {
    id: 'cloudflow',
    brandId: 'on',
    name: 'Cloudflow',
    type: 'shoe',
    image: 'https://placehold.co/400x400?text=Cloudflow', // Temporary placeholder
    description: 'Performance all-day running shoe with responsive cushioning.',
    price: 139.99,
    repairEligible: true,
    refillEligible: false,
    returnEligible: true,
  },
  {
    id: 'cloudx',
    brandId: 'on',
    name: 'Cloud X',
    type: 'shoe',
    image: '/images/products/on/cloudx.png',
    description: 'The ultimate all-around shoe for mixed-sport workouts.',
    price: 149.99,
    repairEligible: true,
    refillEligible: false,
    returnEligible: true,
  },

  // L'Oréal Products
  {
    id: 'revitalift-cream',
    brandId: 'loreal',
    name: 'Revitalift Face Cream',
    type: 'cream',
    image: 'https://placehold.co/400x400?text=Revitalift', // Temporary placeholder
    description: 'Anti-wrinkle + firming day cream with Pro-Retinol.',
    price: 29.99,
    repairEligible: false,
    refillEligible: true,
    returnEligible: true,
  },
  {
    id: 'hyaluronic-serum',
    brandId: 'loreal',
    name: 'Hyaluronic Acid Serum',
    type: 'serum',
    image: 'https://placehold.co/400x400?text=Hyaluronic', // Temporary placeholder
    description: 'Pure hyaluronic acid serum for intense hydration.',
    price: 32.99,
    repairEligible: false,
    refillEligible: true,
    returnEligible: true,
  },
  {
    id: 'age-perfect-lotion',
    brandId: 'loreal',
    name: 'Age Perfect Body Lotion',
    type: 'lotion',
    image: 'https://placehold.co/400x400?text=AgePerfect', // Temporary placeholder
    description: 'Intensive nourishing body treatment for mature skin.',
    price: 24.99,
    repairEligible: false,
    refillEligible: true,
    returnEligible: true,
  },
]
