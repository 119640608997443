import React, { useState, useEffect } from 'react'
import { useParams, NavLink } from 'react-router-dom'
import { viewProductDetails } from '@/domain/retail/products/product-use-cases'
import type { Product } from '@/domain/retail/products/product-types'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import { toast } from '@/shared/components/ui/toast'
import { useCart } from '@/domain/retail/cart/cart-hooks'

export function ProductDetailPage() {
  const { id } = useParams<{ id: string }>()
  const [product, setProduct] = useState<Product | null>(null)
  const [quantity, setQuantity] = useState(1)
  const [size, setSize] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [addingToCart, setAddingToCart] = useState(false)
  const { addItem } = useCart()

  useEffect(() => {
    async function loadProduct() {
      try {
        setLoading(true)
        setError(null)
        if (id) {
          const productData = await viewProductDetails(id)
          setProduct(productData)
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load product')
      } finally {
        setLoading(false)
      }
    }
    loadProduct()
  }, [id])

  const handleAddToCart = async () => {
    if (!product) return

    if (!size) {
      toast.error({
        title: 'Please select a size',
        description: 'You must select a size before adding to cart',
      })
      return
    }

    setAddingToCart(true)

    try {
      await addItem({
        product,
        size,
        quantity,
      })

      toast.success({
        title: 'Added to cart',
        description: `${product.name} has been added to your cart.`,
      })
    } catch (err) {
      toast.error({
        title: 'Error',
        description: 'Failed to add item to cart. Please try again.',
      })
    } finally {
      setAddingToCart(false)
    }
  }

  if (loading) {
    return (
      <div className='flex justify-center items-center h-64'>
        <div className='text-lg text-gray-900 dark:text-gray-100'>Loading product...</div>
      </div>
    )
  }

  if (error || !product) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-16'>
        <Card className='p-6 text-center bg-white dark:bg-gray-800'>
          <h2 className='text-xl font-semibold mb-4 text-gray-900 dark:text-gray-100'>
            {error || 'Product not found'}
          </h2>
          <NavLink
            to='/shop/products'
            className='inline-block bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600 px-6 py-2 rounded-lg transition-colors'
          >
            Back to Products
          </NavLink>
        </Card>
      </div>
    )
  }

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-8'>
        <div>
          <Card className='overflow-hidden bg-white dark:bg-gray-800'>
            <img src={product.image} alt={product.name} className='w-full h-96 object-cover' />
          </Card>
        </div>

        <div className='space-y-6'>
          <div>
            <h1 className='text-3xl font-bold text-gray-900 dark:text-gray-100 mb-2'>
              {product.name}
            </h1>
            <p className='text-lg text-gray-700 dark:text-gray-300 mb-4'>{product.description}</p>
            <p className='text-2xl font-bold text-gray-900 dark:text-gray-100'>
              ${product.price.toFixed(2)}
            </p>
          </div>

          <div className='space-y-4'>
            <div>
              <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>
                Size
              </label>
              <select
                value={size}
                onChange={e => setSize(e.target.value)}
                className='w-full px-4 py-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600'
              >
                <option value=''>Select Size</option>
                {product.sizes.map(s => (
                  <option key={s} value={s}>
                    {s}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className='block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2'>
                Quantity
              </label>
              <input
                type='number'
                value={quantity}
                onChange={e => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                min='1'
                className='w-32 px-4 py-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600'
              />
            </div>

            <Button
              onClick={handleAddToCart}
              disabled={addingToCart}
              className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600 py-3 px-4 rounded-lg text-lg font-semibold transition-colors disabled:opacity-50 disabled:cursor-not-allowed'
            >
              {addingToCart ? 'Adding to Cart...' : 'Add to Cart'}
            </Button>
          </div>

          <div className='pt-6 border-t border-gray-200 dark:border-gray-700'>
            <h2 className='text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100'>
              Product Details
            </h2>
            <div className='space-y-2'>
              <p className='text-gray-700 dark:text-gray-300'>Brand: {product.brand}</p>
              <p className='text-gray-700 dark:text-gray-300'>SKU: {product.sku}</p>
              <p className='text-gray-700 dark:text-gray-300'>Category: {product.category}</p>
              <p className='text-gray-700 dark:text-gray-300'>
                Availability: {product.inStock ? 'In Stock' : 'Out of Stock'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailPage
