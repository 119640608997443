import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import {
  listProducts,
  searchProducts,
  filterProducts,
} from '@/domain/retail/products/product-use-cases'
import { Product } from '@/domain/retail/products/product-types'
import { Card } from '@/shared/components/ui/card'

export const ProductListPage: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [filters, setFilters] = useState({
    category: '',
    brand: '',
    minPrice: undefined as number | undefined,
    maxPrice: undefined as number | undefined,
  })

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        console.log('Fetching products...')
        setLoading(true)
        setError(null)
        let result: Product[]
        if (searchQuery) {
          console.log('Searching products:', searchQuery)
          result = await searchProducts(searchQuery)
        } else if (Object.values(filters).some(value => value !== undefined && value !== '')) {
          console.log('Filtering products:', filters)
          const activeFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== undefined && value !== '')
          )
          result = await filterProducts(activeFilters)
        } else {
          console.log('Listing all products')
          result = await listProducts()
        }
        console.log('Products fetched:', result)
        setProducts(result || [])
      } catch (err) {
        console.error('Error fetching products:', err)
        setError(err instanceof Error ? err.message : 'Failed to fetch products')
        setProducts([])
      } finally {
        setLoading(false)
      }
    }

    fetchProducts()
  }, [searchQuery, filters])

  if (loading) {
    return (
      <div className='flex justify-center items-center h-64'>
        <div className='text-lg text-gray-900 dark:text-gray-100'>Loading products...</div>
      </div>
    )
  }

  if (error) {
    return (
      <div className='flex justify-center items-center h-64'>
        <div className='text-lg text-red-600 dark:text-red-400'>{error}</div>
      </div>
    )
  }

  console.log('Rendering products:', products)

  return (
    <div className='min-h-screen bg-white dark:bg-gray-900 py-8'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex justify-between items-center mb-8'>
          <h1 className='text-3xl font-bold text-gray-900 dark:text-gray-100'>Running Shoes</h1>

          <div className='flex gap-4'>
            <input
              type='search'
              placeholder='Search products...'
              className='px-4 py-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600 placeholder-gray-500 dark:placeholder-gray-400'
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />

            <select
              className='px-4 py-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600'
              value={filters.brand}
              onChange={e => setFilters({ ...filters, brand: e.target.value })}
            >
              <option value=''>All Brands</option>
              <option value='On'>On</option>
            </select>
          </div>
        </div>

        {products.length === 0 ? (
          <div className='text-center py-12'>
            <p className='text-lg text-gray-700 dark:text-gray-300'>No products found</p>
          </div>
        ) : (
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
            {products.map(product => (
              <NavLink key={product.id} to={`/shop/products/${product.id}`}>
                <Card className='h-full hover:shadow-lg transition-shadow duration-200 bg-white dark:bg-gray-800'>
                  <img
                    src={product.image}
                    alt={product.name}
                    className='w-full h-48 object-cover rounded-t-lg'
                  />
                  <div className='p-4'>
                    <h3 className='font-semibold text-lg mb-2 text-gray-900 dark:text-gray-100'>
                      {product.name}
                    </h3>
                    <p className='text-gray-700 dark:text-gray-300 text-sm mb-2'>{product.brand}</p>
                    <div className='flex justify-between items-center'>
                      <span className='font-bold text-lg text-gray-900 dark:text-gray-100'>
                        ${product.price.toFixed(2)}
                      </span>
                      <span
                        className={clsx(
                          'px-2 py-1 rounded text-sm',
                          product.inStock
                            ? 'bg-emerald-100 text-emerald-900 dark:bg-emerald-900 dark:text-emerald-100'
                            : 'bg-red-100 text-red-900 dark:bg-red-900 dark:text-red-100'
                        )}
                      >
                        {product.inStock ? 'In Stock' : 'Out of Stock'}
                      </span>
                    </div>
                  </div>
                </Card>
              </NavLink>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
