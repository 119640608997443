import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '@/shared/components/ui/card'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import { Help, InlineCheck, Return } from '@/assets/icons/Icons'

export const RepairIntroPage: React.FC = () => {
  const [scanning, setScanning] = useState(false)
  const [scannedProductId, setScannedProductId] = useState<string | null>(null)
  const navigate = useNavigate()

  function simulateScan() {
    setScanning(true)
    // Simulate scanning delay
    setTimeout(() => {
      setScannedProductId('cloudmonster2')
      setScanning(false)
    }, 2000)
  }

  function proceed() {
    if (scannedProductId) {
      navigate(`/repair/assess?productId=${scannedProductId}`)
    }
  }

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      {/* Content */}
      <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-end'>
        <KioskButton>
          <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
          <div className='text-lg font-light'>Hey, Phil</div>
        </KioskButton>
      </div>
      <img src={OnLogo} alt='On Logo' className='w-16 h-16 mb-4' />
      <h1 className='text-[2.5rem] font-medium font-manrope text-white'>Repair Service</h1>
      <p className='text-lg font-light text-text-secondary w-full text-center mb-0'>
        Let's Check Your Item. Place it inside the scanner and we'll assess its condition for
        repair.
      </p>

      <div className='bg-black text-wite rounded-xl border border-white sm:min-w-[400px] min-w-[350px] py-6 px-6 space-y-4 mb-8 text-text-primary my-6'>
        <h3 className='font-semibold mb-2 dark:text-white'>Tips for Best Results:</h3>{' '}
        <ul className='space-y-2 fill-text-secondary text-text-secondary'>
          <li className='flex gap-2 items-center border-b border-white/50 pb-2'>
            <InlineCheck />
            Clean the item before scanning
          </li>
          <li className='flex gap-2 items-center border-b border-white/50 pb-2'>
            <InlineCheck />
            Place the item flat on the scanner
          </li>
          <li className='flex gap-2 items-center border-b border-white/50 pb-2'>
            <InlineCheck />
            Ensure good lighting conditions
          </li>
          <li className='flex gap-2 items-center border-b border-white/50 pb-2'>
            <InlineCheck />
            Remove any loose parts or accessories
          </li>{' '}
        </ul>
      </div>
      <KioskButton
        className='bg-yellow-500 text-black border-none py-3 px-6 hover:bg-yellow-400'
        onClick={() => navigate('/repair/scan')}
      >
        Start Scanning
      </KioskButton>

      <div className='fixed z-20 bottom-0 left-0 w-screen flex justify-between p-8'>
        <KioskButton className='px-6' onClick={() => navigate('/')}>
          <Return className='w-5 h-5' />
        </KioskButton>
        <KioskButton>
          <Help className='w-8 h-8' />
        </KioskButton>
      </div>
    </div>
    // <div className='max-w-2xl mx-auto px-4 py-8'>
    //   <h1 className='text-3xl font-bold text-center mb-8 text-gray-900 dark:text-gray-50'>
    //     Repair Service
    //   </h1>

    //   <Card className='mb-8 text-center p-8'>
    //     <h2 className='text-xl font-semibold mb-4 text-gray-900 dark:text-gray-50'>
    //       Let's Check Your Item
    //     </h2>
    //     <p className='text-gray-600 dark:text-gray-300 mb-6'>
    //       Place your item on the scanner and we'll assess its condition for repair.
    //     </p>

    //     {!scannedProductId && (
    //       <div className='space-y-4'>
    //         <div className='w-64 h-64 mx-auto border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg flex items-center justify-center'>
    //           {scanning ? (
    //             <div className='text-center'>
    //               <div className='w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-2'></div>
    //               <p className='text-sm text-gray-500 dark:text-gray-400'>Scanning...</p>
    //             </div>
    //           ) : (
    //             <p className='text-gray-500 dark:text-gray-400 text-center p-4'>Place item here</p>
    //           )}
    //         </div>
    //         <button
    //           onClick={simulateScan}
    //           disabled={scanning}
    //           className={`
    //             w-full py-3 px-4 rounded-lg font-semibold
    //             ${
    //               scanning
    //                 ? 'bg-gray-300 cursor-not-allowed'
    //                 : 'bg-blue-600 hover:bg-blue-700 text-white'
    //             }
    //           `}
    //         >
    //           {scanning ? 'Scanning...' : 'Start Scan'}
    //         </button>
    //       </div>
    //     )}

    //     {scannedProductId && (
    //       <div className='space-y-4'>
    //         <div className='p-4 bg-green-50 dark:bg-green-900 rounded-lg'>
    //           <p className='text-green-700 dark:text-green-100'>✓ Item successfully scanned</p>
    //         </div>
    //         <button
    //           onClick={proceed}
    //           className='w-full py-3 px-4 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-semibold'
    //         >
    //           Continue to Assessment
    //         </button>
    //       </div>
    //     )}
    //   </Card>

    //   <Card className='p-6'>
    //     <h3 className='font-semibold mb-2 dark:text-white'>Tips for Best Results:</h3>
    //     <ul className='list-disc list-inside space-y-2 text-gray-600 dark:text-gray-300'>
    //       <li>Clean the item before scanning</li>
    //       <li>Place the item flat on the scanner</li>
    //       <li>Ensure good lighting conditions</li>
    //       <li>Remove any loose parts or accessories</li>
    //     </ul>
    //   </Card>
    // </div>
  )
}
