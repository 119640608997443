import { eventBus } from '@/domain/events/event-bus'
import { generateId } from '@/shared/utils/id-generator'
import { CartState, AddToCartPayload, UpdateCartItemPayload } from './cart-types'

// Add cart events to EventMap
declare module '@/domain/events/types' {
  interface EventMap {
    CART_ITEM_ADDED: {
      productId: string
      quantity: number
      size: string
    }
    CART_ITEM_REMOVED: {
      productId: string
    }
    CART_CLEARED: {
      timestamp: number
    }
  }
}

type CartSubscriber = () => void

export class CartService {
  private state: CartState = {
    items: [],
    totalItems: 0,
    totalPrice: 0,
  }
  private subscribers: CartSubscriber[] = []

  getState(): CartState {
    return { ...this.state }
  }

  subscribe(callback: CartSubscriber): () => void {
    this.subscribers.push(callback)
    return () => {
      this.subscribers = this.subscribers.filter(cb => cb !== callback)
    }
  }

  private notifySubscribers(): void {
    this.subscribers.forEach(callback => callback())
  }

  addItem({ product, quantity, size }: AddToCartPayload) {
    const existingItem = this.state.items.find(
      item => item.product.id === product.id && item.size === size
    )

    if (existingItem) {
      existingItem.quantity += quantity
    } else {
      this.state.items.push({ product, quantity, size })
    }

    this.calculateTotals()
    eventBus.emit({
      id: generateId(),
      type: 'CART_ITEM_ADDED',
      timestamp: Date.now(),
      payload: { productId: product.id, quantity, size },
    })
    this.notifySubscribers()
  }

  updateItem(productId: string, updates: UpdateCartItemPayload) {
    const item = this.state.items.find(item => item.product.id === productId)
    if (item) {
      if (updates.quantity !== undefined) {
        item.quantity = updates.quantity
      }
      if (updates.size !== undefined) {
        item.size = updates.size
      }
      this.calculateTotals()
    }
    this.notifySubscribers()
  }

  removeItem(productId: string) {
    this.state.items = this.state.items.filter(item => item.product.id !== productId)
    this.calculateTotals()
    eventBus.emit({
      id: generateId(),
      type: 'CART_ITEM_REMOVED',
      timestamp: Date.now(),
      payload: { productId },
    })
    this.notifySubscribers()
  }

  clearCart() {
    this.state = {
      items: [],
      totalItems: 0,
      totalPrice: 0,
    }
    eventBus.emit({
      id: generateId(),
      type: 'CART_CLEARED',
      timestamp: Date.now(),
      payload: { timestamp: Date.now() },
    })
    this.notifySubscribers()
  }

  private calculateTotals() {
    this.state.totalItems = this.state.items.reduce((sum, item) => sum + item.quantity, 0)
    this.state.totalPrice = this.state.items.reduce(
      (sum, item) => sum + item.product.price * item.quantity,
      0
    )
  }
}

// Export singleton instance
export const cartService = new CartService()
