import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import {
  getCartState,
  updateCartItem,
  removeFromCart,
} from '@/domain/retail/products/product-use-cases'
import { CartState } from '@/domain/retail/cart/cart-types'
import { Card } from '@/shared/components/ui/card'
import { cartService } from '@/domain/retail/cart/cart-service'

export const CartPage: React.FC = () => {
  const [cart, setCart] = useState<CartState>(getCartState())

  useEffect(() => {
    // Subscribe to cart updates
    const unsubscribe = cartService.subscribe(() => {
      setCart(getCartState())
    })

    // Initial cart state
    setCart(getCartState())

    // Cleanup subscription
    return () => unsubscribe()
  }, [])

  if (cart.items.length === 0) {
    return (
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16'>
        <Card className='text-center py-16 bg-white dark:bg-gray-800'>
          <h2 className='text-2xl font-semibold mb-4 text-gray-900 dark:text-gray-100'>
            Your cart is empty
          </h2>
          <p className='text-gray-700 dark:text-gray-300 mb-8'>
            Looks like you haven't added any items to your cart yet.
          </p>
          <NavLink
            to='/shop/products'
            className='inline-block bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600 px-6 py-3 rounded-lg transition-colors'
          >
            Continue Shopping
          </NavLink>
        </Card>
      </div>
    )
  }

  return (
    <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
      <h1 className='text-3xl font-bold mb-8 text-gray-900 dark:text-gray-100'>Shopping Cart</h1>

      <div className='grid grid-cols-1 lg:grid-cols-12 gap-8'>
        <div className='lg:col-span-8'>
          <Card className='divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800'>
            {cart.items.map(item => (
              <div key={`${item.product.id}-${item.size}`} className='p-6'>
                <div className='flex items-center'>
                  <img
                    src={item.product.image}
                    alt={item.product.name}
                    className='w-24 h-24 object-cover rounded'
                  />
                  <div className='ml-6 flex-1'>
                    <div className='flex justify-between'>
                      <div>
                        <h3 className='text-lg font-semibold text-gray-900 dark:text-gray-100'>
                          {item.product.name}
                        </h3>
                        <p className='text-gray-700 dark:text-gray-300'>Size: {item.size}</p>
                      </div>
                      <p className='text-lg font-semibold text-gray-900 dark:text-gray-100'>
                        ${(item.product.price * item.quantity).toFixed(2)}
                      </p>
                    </div>
                    <div className='mt-4 flex items-center justify-between'>
                      <div className='flex items-center space-x-4'>
                        <button
                          onClick={() =>
                            updateCartItem(item.product.id, {
                              quantity: Math.max(1, item.quantity - 1),
                            })
                          }
                          className='p-1 border rounded text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800'
                        >
                          -
                        </button>
                        <span className='text-lg text-gray-900 dark:text-gray-100'>
                          {item.quantity}
                        </span>
                        <button
                          onClick={() =>
                            updateCartItem(item.product.id, {
                              quantity: item.quantity + 1,
                            })
                          }
                          className='p-1 border rounded text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800'
                        >
                          +
                        </button>
                      </div>
                      <button
                        onClick={() => removeFromCart(item.product.id)}
                        className='text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300'
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Card>
        </div>

        <div className='lg:col-span-4'>
          <Card className='p-6 bg-white dark:bg-gray-800'>
            <h2 className='text-lg font-semibold mb-4 text-gray-900 dark:text-gray-100'>
              Order Summary
            </h2>
            <div className='space-y-4'>
              <div className='flex justify-between'>
                <span className='text-gray-700 dark:text-gray-300'>Subtotal</span>
                <span className='font-semibold text-gray-900 dark:text-gray-100'>
                  ${cart.totalPrice.toFixed(2)}
                </span>
              </div>
              <div className='flex justify-between'>
                <span className='text-gray-700 dark:text-gray-300'>Shipping</span>
                <span className='font-semibold text-gray-900 dark:text-gray-100'>Free</span>
              </div>
              <div className='border-t pt-4 flex justify-between border-gray-200 dark:border-gray-700'>
                <span className='text-lg font-semibold text-gray-900 dark:text-gray-100'>
                  Total
                </span>
                <span className='text-lg font-semibold text-gray-900 dark:text-gray-100'>
                  ${cart.totalPrice.toFixed(2)}
                </span>
              </div>
            </div>
            <NavLink
              to='/shop/checkout'
              className='mt-6 w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600 py-3 px-4 rounded-lg text-center font-semibold transition-colors'
            >
              Proceed to Checkout
            </NavLink>
          </Card>
        </div>
      </div>
    </div>
  )
}
