import { createBrowserRouter } from 'react-router-dom'
import { kioskRoutes } from './app/routes'
import { AdminRoutes } from './admin/routes'

export const router = createBrowserRouter([
  {
    path: '/',
    element: kioskRoutes[0].element,
    children: [
      { index: true, element: kioskRoutes[0].children?.find(route => route.index)?.element },
      ...(kioskRoutes[0].children?.filter(route => !route.index) || []),
    ],
  },
  ...AdminRoutes(),
])
