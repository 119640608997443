import { RouteObject } from 'react-router-dom'
import { KioskLayout } from './layout/KioskLayout'
import { HomePage } from '@/features/home/HomePage'
import { ProductListPage, ProductDetailPage, CartPage, CheckoutPage } from '@/features/shopping'
import {
  RepairIntroPage,
  RepairAssessmentPage,
  RepairConfirmationPage,
  RepairSuccessPage,
} from '@/features/repair'
import RepairScanPage from '@/features/repair/RepairScanPage'
import {
  ReturnIntroPage,
  ReturnAssessmentPage,
  ReturnConfirmationPage,
  ReturnSuccessPage,
} from '@/features/return'
import {
  RefillIntroPage,
  RefillAssessmentPage,
  RefillOptionsPage,
  RefillConfirmationPage,
  RefillSuccessPage,
} from '@/features/refill'
import ReturnScanPage from '@/features/return/ReturnScanPage'
import ShopLayout from '@/features/shopping/ShopLayout'

export const kioskRoutes: RouteObject[] = [
  {
    path: '/',
    element: <KioskLayout />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: 'shop',
        element: <ShopLayout />,
        children: [
          { path: 'products', element: <ProductListPage /> },
          { path: 'products/:id', element: <ProductDetailPage /> },
          { path: 'cart', element: <CartPage /> },
          { path: 'checkout', element: <CheckoutPage /> },
        ],
      },

      // Repair flow
      { path: 'repair', element: <RepairIntroPage /> },
      { path: 'repair/scan', element: <RepairScanPage /> },
      { path: 'repair/assess', element: <RepairAssessmentPage /> },
      { path: 'repair/confirm', element: <RepairConfirmationPage /> },
      { path: 'repair/success', element: <RepairSuccessPage /> },

      // Return flow
      { path: 'return', element: <ReturnIntroPage /> },
      { path: 'return/scan', element: <ReturnScanPage /> },
      { path: 'return/assess', element: <ReturnAssessmentPage /> },
      { path: 'return/confirm', element: <ReturnConfirmationPage /> },
      { path: 'return/success', element: <ReturnSuccessPage /> },

      // Refill flow
      { path: 'refill', element: <RefillIntroPage /> },
      { path: 'refill/assess', element: <RefillAssessmentPage /> },
      { path: 'refill/options', element: <RefillOptionsPage /> },
      { path: 'refill/confirm', element: <RefillConfirmationPage /> },
      { path: 'refill/success', element: <RefillSuccessPage /> },
    ],
  },
]
