import OpenAI from 'openai'
import { enhancedSpeechRecognition } from './enhanced-speech-recognition'

interface AudioConfig {
  voice: string
  rate: number
  pitch: number
  volume: number
}

export class AudioManager {
  private openai: OpenAI
  private config: AudioConfig = {
    voice: 'alloy',
    rate: 1.0,
    pitch: 1.0,
    volume: 1.0,
  }
  private audio: HTMLAudioElement | null = null
  private isSpeaking: boolean = false
  private speakQueue: string[] = []

  constructor() {
    console.log('AudioManager: Initializing...')
    const apiKey = import.meta.env.VITE_OPENAI_API_KEY
    if (!apiKey) {
      console.error('AudioManager: OpenAI API key is missing!')
      throw new Error('OpenAI API key is required for text-to-speech')
    }

    this.openai = new OpenAI({
      apiKey,
      dangerouslyAllowBrowser: true,
    })
    console.log('AudioManager: Initialized with API key:', !!apiKey)
  }

  async speak(text: string): Promise<void> {
    if (!text || !text.trim()) {
      console.warn('AudioManager: Empty text provided, skipping')
      return
    }

    console.log('AudioManager: Received text to speak:', text)
    this.speakQueue.push(text)
    console.log('AudioManager: Queue length:', this.speakQueue.length)

    if (this.isSpeaking) {
      console.log('AudioManager: Already speaking, queued message')
      return
    }

    await this.processQueue()
  }

  private async processQueue(): Promise<void> {
    if (this.speakQueue.length === 0) {
      console.log('AudioManager: Queue empty')
      return
    }

    if (this.isSpeaking) {
      console.log('AudioManager: Already speaking')
      return
    }

    try {
      this.isSpeaking = true
      enhancedSpeechRecognition.setSpeaking(true)
      console.log('AudioManager: Processing next item in queue')

      const text = this.speakQueue[0]
      console.log('AudioManager: Generating speech for:', text)

      const mp3 = await this.openai.audio.speech.create({
        model: 'tts-1-hd',
        voice: this.config.voice as 'alloy' | 'echo' | 'fable' | 'onyx' | 'nova' | 'shimmer',
        input: text,
      })
      console.log('AudioManager: Speech generated successfully')

      const blob = await mp3.blob()
      const url = URL.createObjectURL(blob)
      console.log('AudioManager: Created audio URL')

      // Stop any currently playing audio
      if (this.audio) {
        this.audio.pause()
        this.audio.currentTime = 0
      }

      await new Promise<void>((resolve, reject) => {
        this.audio = new Audio(url)
        this.audio.volume = this.config.volume
        console.log('AudioManager: Created Audio element with volume:', this.config.volume)

        this.audio.onended = () => {
          console.log('AudioManager: Audio playback completed')
          URL.revokeObjectURL(url)
          this.isSpeaking = false
          enhancedSpeechRecognition.setSpeaking(false)
          this.speakQueue.shift()
          resolve()
          // Process next item in queue after a short delay
          setTimeout(() => this.processQueue(), 100)
        }

        this.audio.onerror = error => {
          console.error('AudioManager: Audio playback error:', error)
          URL.revokeObjectURL(url)
          this.isSpeaking = false
          enhancedSpeechRecognition.setSpeaking(false)
          this.speakQueue.shift()
          reject(error)
          // Process next item in queue after error
          setTimeout(() => this.processQueue(), 100)
        }

        console.log('AudioManager: Starting audio playback')
        this.audio.play().catch(error => {
          console.error('AudioManager: Failed to start playback:', error)
          reject(error)
        })
      })
    } catch (error) {
      console.error('AudioManager: Error in processQueue:', error)
      this.isSpeaking = false
      enhancedSpeechRecognition.setSpeaking(false)
      this.speakQueue.shift()
      // Process next item in queue after error
      setTimeout(() => this.processQueue(), 100)
    }
  }

  stop(): void {
    if (this.audio) {
      console.log('AudioManager: Stopping audio playback')
      this.audio.pause()
      this.audio.currentTime = 0
    }
    this.isSpeaking = false
    enhancedSpeechRecognition.setSpeaking(false)
    this.speakQueue = []
  }

  setVoice(voice: 'alloy' | 'echo' | 'fable' | 'onyx' | 'nova' | 'shimmer'): void {
    this.config.voice = voice
  }

  setVolume(volume: number): void {
    this.config.volume = Math.max(0, Math.min(1, volume))
    if (this.audio) {
      this.audio.volume = this.config.volume
    }
  }
}

export const audioManager = new AudioManager()
