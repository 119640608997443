import { audioManager } from './audio-manager'

class ElevenLabsTTS {
  private apiKey: string
  private voiceId: string
  private modelId: string
  private baseUrl = 'https://api.elevenlabs.io/v1'

  constructor() {
    this.apiKey = import.meta.env.VITE_ELEVENLABS_API_KEY
    this.voiceId = import.meta.env.VITE_ELEVENLABS_VOICE_ID || 'default'
    this.modelId = import.meta.env.VITE_ELEVENLABS_MODEL_ID || 'eleven_monolingual_v1'

    console.log('API Key loaded:', this.apiKey ? 'Yes' : 'No')

    if (!this.apiKey) {
      console.warn('ElevenLabs API key is not configured. Please check your .env file.')
    }
  }

  async speak(text: string): Promise<void> {
    if (!this.apiKey) {
      throw new Error('ElevenLabs API key is not configured')
    }

    try {
      const response = await fetch(`${this.baseUrl}/text-to-speech/${this.voiceId}`, {
        method: 'POST',
        headers: {
          Accept: 'audio/mpeg',
          'Content-Type': 'application/json',
          'xi-api-key': this.apiKey,
        },
        body: JSON.stringify({
          text,
          model_id: this.modelId,
          voice_settings: {
            stability: 0.75,
            similarity_boost: 0.75,
          },
        }),
      })

      if (!response.ok) {
        throw new Error(`ElevenLabs API error: ${response.statusText}`)
      }

      const audioBlob = await response.blob()
      const audioUrl = URL.createObjectURL(audioBlob)
      await audioManager.play(audioUrl)

      // Clean up the URL after playback
      URL.revokeObjectURL(audioUrl)
    } catch (error) {
      console.error('Error generating or playing speech:', error)
      throw error
    }
  }

  setVoice(voiceId: string) {
    this.voiceId = voiceId
  }

  setModel(modelId: string) {
    this.modelId = modelId
  }

  stop() {
    audioManager.stop()
  }
}

export const elevenLabsTTS = new ElevenLabsTTS()
