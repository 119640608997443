import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/shared/components/ui/button'
import { Card } from '@/shared/components/ui/card'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import { Help, Return } from '@/assets/icons/Icons'

export const ReturnIntroPage: React.FC = () => {
  const [scannedProductId, setScannedProductId] = useState<string | null>(null)
  const [isScanning, setIsScanning] = useState(false)
  const navigate = useNavigate()

  function simulateScan() {
    setIsScanning(true)
    // Simulate scanning delay
    setTimeout(() => {
      setScannedProductId('cloudmonster')
      setIsScanning(false)
    }, 1500)
  }

  function proceed() {
    if (scannedProductId) {
      navigate(`/return/assess?productId=${scannedProductId}`)
    }
  }

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      {/* Content */}
      <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-end'>
        <KioskButton>
          <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
          <div className='text-lg font-light'>Hey, Phil</div>
        </KioskButton>
      </div>
      <img src={OnLogo} alt='On Logo' className='w-16 h-16 mb-4' />
      <h1 className='text-[2.5rem] font-medium font-manrope text-white'>Product Returns</h1>
      <p className='text-lg font-light text-text-secondary w-full text-center mb-8'>
        To begin the returns process, please place your item into the scanner.
      </p>
      <KioskButton
        className='bg-yellow-500 text-black border-none py-3 px-6 hover:bg-yellow-400'
        onClick={() => navigate('/return/scan')}
      >
        Start Scanning
      </KioskButton>

      <div className='fixed z-20 bottom-0 left-0 w-screen flex justify-between p-8'>
        <KioskButton className='px-6' onClick={() => navigate('/')}>
          <Return className='w-5 h-5' />
        </KioskButton>
        <KioskButton>
          <Help className='w-8 h-8' />
        </KioskButton>
      </div>
    </div>
    // <div className='max-w-2xl mx-auto px-4 py-8'>
    //   <Card className='p-6 bg-white dark:bg-gray-800'>
    //     <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100'>
    //       Product Returns
    //     </h2>
    //     <p className='text-gray-700 dark:text-gray-300 mb-6'>
    //       To begin the return process, please place your item on the scanner and we'll assess its
    //       eligibility.
    //     </p>

    //     <div className='space-y-4'>
    //       {isScanning ? (
    //         <div className='text-center py-8'>
    //           <div className='animate-pulse text-emerald-600 dark:text-emerald-400'>
    //             Scanning item...
    //           </div>
    //         </div>
    //       ) : scannedProductId ? (
    //         <div className='space-y-4'>
    //           <div className='p-4 bg-emerald-50 dark:bg-emerald-900 rounded-lg'>
    //             <p className='text-emerald-800 dark:text-emerald-100'>Item scanned successfully!</p>
    //           </div>
    //           <div className='flex justify-end space-x-4'>
    //             <Button
    //               variant='outline'
    //               onClick={() => setScannedProductId(null)}
    //               className='text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600'
    //             >
    //               Scan Again
    //             </Button>
    //             <Button
    //               onClick={proceed}
    //               className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
    //             >
    //               Continue
    //             </Button>
    //           </div>
    //         </div>
    //       ) : (
    //         <div className='flex justify-center'>
    //           <Button
    //             size='lg'
    //             onClick={simulateScan}
    //             className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
    //           >
    //             Simulate Item Scanning
    //           </Button>
    //         </div>
    //       )}
    //     </div>
    //   </Card>
    // </div>
  )
}
