import { cartService } from '../cart/cart-service'
import { productService } from './product-service'
import type { UpdateCartItemPayload } from '../cart/cart-types'
import type { Product } from './product-types'

export async function viewProductDetails(productId: string): Promise<Product> {
  return productService.getProductDetails(productId)
}

export function getCartState() {
  return cartService.getState()
}

export function listProducts(): Promise<Product[]> {
  return productService.listProducts()
}

export function searchProducts(query: string): Promise<Product[]> {
  return productService.searchProducts(query)
}

export function filterProducts(filters: Record<string, unknown>): Promise<Product[]> {
  return productService.filterProducts(filters)
}

export async function addToCart(product: Product, quantity: number, size: string) {
  try {
    cartService.addItem({ product, quantity, size })
    return { success: true }
  } catch (error) {
    console.error('Failed to add item to cart:', error)
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to add to cart',
    }
  }
}

export async function updateCartItem(
  productId: string,
  updates: Partial<Omit<UpdateCartItemPayload, 'productId'>>
) {
  try {
    cartService.updateItem(productId, { productId, ...updates })
    return { success: true }
  } catch (error) {
    console.error('Failed to update cart item:', error)
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to update cart',
    }
  }
}

export async function removeFromCart(productId: string) {
  try {
    cartService.removeItem(productId)
    return { success: true }
  } catch (error) {
    console.error('Failed to remove item from cart:', error)
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to remove from cart',
    }
  }
}

export async function clearCart() {
  try {
    cartService.clearCart()
    return { success: true }
  } catch (error) {
    console.error('Failed to clear cart:', error)
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to clear cart',
    }
  }
}
