import { useState } from 'react'
import {
  assessForReturn,
  startReturn,
  completeReturn,
  getReturnDetails,
} from '@/domain/retail/returns/return-use-cases'
import type { ReturnAssessmentResult, ReturnMethod } from '@/domain/retail/returns/return-types'

export function useReturnAssessment() {
  const [assessment, setAssessment] = useState<ReturnAssessmentResult | null>(null)
  const [isAssessing, setIsAssessing] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function assessItemForReturn(productId: string) {
    try {
      setIsAssessing(true)
      setError(null)
      const result = await assessForReturn(productId)
      setAssessment(result)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to assess item')
      setAssessment(null)
    } finally {
      setIsAssessing(false)
    }
  }

  return { assessment, isAssessing, error, assessItemForReturn }
}

export function useReturnProcess() {
  const [isProcessing, setIsProcessing] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function initiateReturn(productId: string, method: ReturnMethod, value: number) {
    try {
      setIsProcessing(true)
      setError(null)
      const request = await startReturn(productId, method)
      return request
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to initiate return')
      throw err
    } finally {
      setIsProcessing(false)
    }
  }

  async function finalizeReturn(requestId: string) {
    try {
      setIsProcessing(true)
      setError(null)
      const request = await completeReturn(requestId)
      return request
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to complete return')
      throw err
    } finally {
      setIsProcessing(false)
    }
  }

  return {
    isProcessing,
    error,
    initiateReturn,
    finalizeReturn,
  }
}

export function useReturnDetails(requestId: string) {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function fetchDetails() {
    try {
      setIsLoading(true)
      setError(null)
      const details = getReturnDetails(requestId)
      return details
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch return details')
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    error,
    fetchDetails,
  }
}
