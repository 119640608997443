import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useRefillProcess } from './use-cases'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import type { RefillDeliveryMethod } from '@/domain/retail/refills/refill-types'

export const RefillSuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId') || ''
  const method = searchParams.get('method') as RefillDeliveryMethod
  const subscription = searchParams.get('subscription') === 'true'
  const cost = Number(searchParams.get('cost') || 0)

  const { request, loading, error, complete } = useRefillProcess()
  const navigate = useNavigate()

  useEffect(() => {
    if (request?.id) {
      complete(request.id)
    }
  }, [request?.id])

  if (loading) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-background-secondary'>
          <p className='text-center text-text-secondary'>Finalizing your refill...</p>
        </Card>
      </div>
    )
  }

  if (error) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-background-secondary'>
          <h2 className='text-2xl font-bold mb-4 text-text-primary'>Error</h2>
          <p className='text-status-error mb-6'>{error}</p>
          <Button
            className='w-full bg-brand-primary text-text-primary hover:bg-brand-secondary'
            onClick={() => navigate('/refill')}
          >
            Return Home
          </Button>
        </Card>
      </div>
    )
  }

  return (
    <div className='max-w-md mx-auto px-4 py-8'>
      <Card className='p-6 bg-white dark:bg-gray-800'>
        <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-white'>
          Refill Request Successful!
        </h2>

        <div className='space-y-4 mb-6'>
          <p className='text-gray-700 dark:text-gray-300'>
            Your refill request for product #{productId} has been confirmed.
          </p>

          <div className='bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg border border-gray-200 dark:border-gray-700'>
            <div className='space-y-2'>
              <div className='flex justify-between items-center'>
                <span className='text-gray-700 dark:text-gray-300'>Delivery Method:</span>
                <span className='font-semibold text-gray-900 dark:text-white'>
                  {method === 'inStore' ? 'In-Store Pickup' : 'Home Delivery'}
                </span>
              </div>

              <div className='flex justify-between items-center'>
                <span className='text-gray-700 dark:text-gray-300'>Subscription:</span>
                <span className='font-semibold text-gray-900 dark:text-white'>
                  {subscription ? 'Yes' : 'No'}
                </span>
              </div>

              <div className='flex justify-between items-center'>
                <span className='text-gray-700 dark:text-gray-300'>Total Cost:</span>
                <span className='font-semibold text-gray-900 dark:text-white'>
                  ${cost.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>

        <Button
          className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
          onClick={() => navigate('/')}
        >
          Return to Home
        </Button>
      </Card>
    </div>
  )
}
