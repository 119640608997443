import { useState } from 'react'
import {
  assessRefill,
  fetchRefillOptions,
  startRefill,
  finishRefill,
  getRefillDetails,
  getRefillHistory,
} from '@/domain/retail/refills/refill-use-cases'
import type {
  RefillAssessmentResult,
  RefillOptions,
  RefillRequest,
  RefillHistory,
  RefillDeliveryMethod,
} from '@/domain/retail/refills/refill-types'

export function useRefillAssessment() {
  const [assessment, setAssessment] = useState<RefillAssessmentResult | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function assessContainer(containerId: string) {
    try {
      setLoading(true)
      setError(null)
      const result = assessRefill(containerId)
      setAssessment(result)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to assess container')
    } finally {
      setLoading(false)
    }
  }

  return { assessment, loading, error, assessContainer }
}

export function useRefillOptions(productId: string) {
  const [options, setOptions] = useState<RefillOptions | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function loadOptions() {
    try {
      setLoading(true)
      setError(null)
      const result = fetchRefillOptions()
      setOptions(result)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load refill options')
    } finally {
      setLoading(false)
    }
  }

  return { options, loading, error, loadOptions }
}

export function useRefillProcess() {
  const [request, setRequest] = useState<RefillRequest | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function initiate(
    productId: string,
    method: RefillDeliveryMethod,
    subscription: boolean,
    cost: number
  ) {
    try {
      setLoading(true)
      setError(null)
      const result = startRefill(productId, method, subscription, cost)
      setRequest(result)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to initiate refill')
    } finally {
      setLoading(false)
    }
  }

  async function complete(requestId: string) {
    try {
      setLoading(true)
      setError(null)
      const result = finishRefill(requestId)
      setRequest(result)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to complete refill')
    } finally {
      setLoading(false)
    }
  }

  return { request, loading, error, initiate, complete }
}

export function useRefillDetails(requestId: string) {
  const [request, setRequest] = useState<RefillRequest | null>(null)
  const [history, setHistory] = useState<RefillHistory | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function loadDetails() {
    try {
      setLoading(true)
      setError(null)
      const requestDetails = getRefillDetails(requestId)
      const historyDetails = getRefillHistory(requestId)
      if (requestDetails) setRequest(requestDetails)
      if (historyDetails) setHistory(historyDetails)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load refill details')
    } finally {
      setLoading(false)
    }
  }

  return { request, history, loading, error, loadDetails }
}
