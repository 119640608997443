import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useRepairAssessment, useRepairProcess } from './use-cases'
import { Card } from '@/shared/components/ui/card'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import OnIcon from '@/assets/logos/onIcon.svg'
import OnSpinner from '@/assets/animated/OnSpinner'
import { Check, InlineCheck } from '@/assets/icons/Icons'

export const RepairConfirmationPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { assessment, loading: assessmentLoading, assessItem } = useRepairAssessment()
  const { initiateRepair, loading: processLoading } = useRepairProcess()
  const [customerNotes, setCustomerNotes] = useState('')
  const [confirmed, setConfirmed] = useState(false)
  const [ready, setReady] = useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, 3000)

    return () => clearTimeout(timer)
  }, [])

  const productId = searchParams.get('productId')

  useEffect(() => {
    if (productId && !assessment) {
      assessItem(productId)
    }
  }, [productId, assessment])

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      {ready ? (
        <>
          <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-between'>
            <div className='flex items-center gap-4'>
              <img
                src={OnIcon}
                alt='ON Logo'
                className='w-[48px] h-[48px]'
                onClick={() => navigate('/')}
              />
            </div>
            <KioskButton>
              <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
              <div className='text-lg font-light'>Hey, Phil</div>
            </KioskButton>
          </div>
          {/* <img src={OnLogo} alt='On Logo' className='w-16 h-16 mb-4' /> */}
          <div className='w-full flex justify-center pb-2'>
            <Check className='w-12 h-12' />
          </div>
          <h1 className='text-[2.5rem] font-medium font-manrope text-white -mb-2'>
            Repair Started!
          </h1>
          <p className='text-lg text-text-secondary font-light w-full text-center mb-6'>
            Thank you for your repair request.
          </p>
          <div className='bg-black text-wite rounded-xl sm:min-w-[500px] min-w-[350px] py-6 px-6 space-y-4 mb-4 text-text-primary'>
            <h3 className='text-xl font-bold mb-4'>Repair Summary</h3>
            <div className='space-y-3'>
              <div className='flex justify-between items-center'>
                <span className='dark:text-gray-300'>Repair ID:</span>
                <span className='font-semibold'>fbag2iqwq8em4n1xm9a</span>
              </div>
              <div className='flex justify-between items-center'>
                <span className='dark:text-gray-300'>Estimated Completion</span>
                <span className='font-semibold mb-2'>
                  {new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                  })}
                </span>
              </div>
              <h3 className='pt-4 border-t'>What's Next?</h3>{' '}
              <ul className='space-y-2 font-light'>
                <li className='flex gap-2 items-center'>
                  <InlineCheck />
                  We'll begin working on your repair immediately
                </li>
                <li className='flex gap-2 items-center'>
                  <InlineCheck />
                  You'll receive updates on the repair progress
                </li>
                <li className='flex gap-2 items-center'>
                  <InlineCheck />
                  We'll notify you when your item is ready for pickup
                </li>
                <li className='flex gap-2 items-center'>
                  <InlineCheck />
                  Payment will be collected upon completion
                </li>{' '}
              </ul>
            </div>
          </div>
          <KioskButton
            className='bg-yellow-500 text-black border-none py-3 px-6 hover:bg-yellow-400'
            onClick={() => navigate(`/`)}
          >
            Return Home
          </KioskButton>
        </>
      ) : (
        <OnSpinner className='w-[200px] h-[200px]' />
      )}
    </div>
    // <div className='max-w-2xl mx-auto px-4 py-8'>
    //   <h1 className='text-3xl font-bold text-center mb-8 text-gray-900 dark:text-gray-50'>
    //     Confirm Repair Request
    //   </h1>

    //   <Card className='mb-6 p-6'>
    //     <h2 className='text-xl font-semibold mb-4 text-gray-900 dark:text-gray-50'>
    //       Review Details
    //     </h2>

    //     <div className='space-y-6'>
    //       <div className='grid grid-cols-2 gap-4 text-sm'>
    //         <div>
    //           <p className='text-gray-700 dark:text-gray-300'>Total Cost</p>
    //           <p className='font-medium text-lg text-gray-900 dark:text-gray-50'>
    //             ${item.repairCost.toFixed(2)}
    //           </p>
    //         </div>
    //         <div>
    //           <p className='text-gray-700 dark:text-gray-300'>Estimated Time</p>
    //           <p className='font-medium text-gray-900 dark:text-gray-50'>
    //             {item.estimatedTimeDays} days
    //           </p>
    //         </div>
    //         <div className='col-span-2'>
    //           <p className='text-gray-700 dark:text-gray-300'>Estimated Completion</p>
    //           <p className='font-medium text-gray-900 dark:text-gray-50'>
    //             {estimatedCompletion.toLocaleDateString('en-US', {
    //               weekday: 'long',
    //               year: 'numeric',
    //               month: 'long',
    //               day: 'numeric',
    //             })}
    //           </p>
    //         </div>
    //       </div>

    //       <div>
    //         <label
    //           htmlFor='notes'
    //           className='block font-medium mb-2 text-gray-900 dark:text-gray-50'
    //         >
    //           Additional Notes (Optional)
    //         </label>
    //         <textarea
    //           id='notes'
    //           rows={3}
    //           className='w-full px-3 py-2 border rounded-lg dark:bg-gray-800 dark:border-gray-600 text-gray-900 dark:text-gray-50 placeholder-gray-500 dark:placeholder-gray-400'
    //           placeholder='Any specific concerns or requests...'
    //           value={customerNotes}
    //           onChange={e => setCustomerNotes(e.target.value)}
    //         />
    //       </div>

    //       <div className='bg-blue-50 dark:bg-blue-900/50 p-4 rounded-lg'>
    //         <h3 className='font-semibold mb-2 text-gray-900 dark:text-gray-50'>What's Next?</h3>
    //         <ul className='list-disc list-inside space-y-1 text-sm text-gray-700 dark:text-gray-300'>
    //           <li>We'll begin working on your repair immediately</li>
    //           <li>You'll receive updates on the repair progress</li>
    //           <li>We'll notify you when your item is ready for pickup</li>
    //           <li>Payment will be collected upon completion</li>
    //         </ul>
    //       </div>
    //     </div>
    //   </Card>

    //   <div className='space-y-3'>
    //     <button
    //       onClick={handleConfirm}
    //       disabled={confirmed}
    //       className={`
    //         w-full py-3 px-4 rounded-lg font-semibold
    //         ${
    //           confirmed
    //             ? 'bg-gray-400 cursor-not-allowed text-white'
    //             : 'bg-blue-600 hover:bg-blue-700 text-white'
    //         }
    //       `}
    //     >
    //       {confirmed ? 'Processing...' : 'Confirm Repair Request'}
    //     </button>
    //     <button
    //       onClick={() => navigate('/repair/assess?productId=' + productId)}
    //       disabled={confirmed}
    //       className='w-full py-3 px-4 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300 font-semibold'
    //     >
    //       Back to Assessment
    //     </button>
    //   </div>
    // </div>
  )
}
