import { TypedEventEmitter } from '../../../shared/utils/event-emitter'
import { enhancedSpeechRecognition } from '../../../shared/utils/enhanced-speech-recognition'
import { VoiceCommand, VoiceEvent } from './types'

interface VoiceEvents {
  VOICE_COMMAND: VoiceEvent<'VOICE_COMMAND'>
  VOICE_ERROR: VoiceEvent<'VOICE_ERROR'>
  USER_ACTION: VoiceEvent<'USER_ACTION'>
  TRANSCRIPT_UPDATE: VoiceEvent<'TRANSCRIPT_UPDATE'>
}

export class VoiceProcessor extends TypedEventEmitter<VoiceEvents> {
  private recognition: typeof enhancedSpeechRecognition
  private isListening: boolean = false

  constructor() {
    super()
    this.recognition = enhancedSpeechRecognition
    this.setupRecognition()
  }

  private setupRecognition() {
    // Handle recognition results
    const handleResult = (results: SpeechRecognitionResultList) => {
      if (results && results.length > 0) {
        const result = results[results.length - 1]
        if (result && result[0]) {
          const transcript = result[0].transcript?.trim() || ''
          const confidence = result[0].confidence || 0

          if (!transcript) return

          // Emit transcript update event
          this.emit('TRANSCRIPT_UPDATE', {
            type: 'TRANSCRIPT_UPDATE',
            timestamp: Date.now(),
            payload: { transcript, confidence },
          })

          const command: VoiceCommand = {
            type: 'voice_command',
            text: transcript,
            confidence,
            timestamp: Date.now(),
          }

          this.emit('VOICE_COMMAND', {
            type: 'VOICE_COMMAND',
            timestamp: Date.now(),
            payload: { command },
          })
        }
      }
    }

    // Handle recognition errors
    const handleError = (error: Error) => {
      console.error('Speech recognition error:', error)
      this.emit('VOICE_ERROR', {
        type: 'VOICE_ERROR',
        timestamp: Date.now(),
        payload: { error },
      })
    }

    // Set up the recognition instance
    if (this.recognition.setupRecognition) {
      this.recognition.setupRecognition({
        onStart: () => {
          this.isListening = true
          this.emit('USER_ACTION', {
            type: 'USER_ACTION',
            timestamp: Date.now(),
            payload: {
              action: 'submit',
              target: 'voice-recognition-start',
              metadata: {
                source: 'voice',
                timestamp: Date.now(),
              },
            },
          })
        },
        onEnd: () => (this.isListening = false),
        onError: handleError,
        onResult: handleResult,
        config: {
          continuous: true,
          interimResults: true,
          language: 'en-US',
        },
      })
    }
  }

  async start(): Promise<void> {
    if (this.isListening) {
      return
    }

    try {
      await this.recognition.start()
    } catch (error) {
      const voiceError =
        error instanceof Error ? error : new Error('Failed to start voice recognition')
      console.error('Failed to start voice recognition:', voiceError)

      this.emit('VOICE_ERROR', {
        type: 'VOICE_ERROR',
        timestamp: Date.now(),
        payload: { error: voiceError },
      })
      throw voiceError
    }
  }

  stop(): void {
    if (!this.isListening) {
      return
    }

    try {
      this.recognition.stop()
      this.emit('USER_ACTION', {
        type: 'USER_ACTION',
        timestamp: Date.now(),
        payload: {
          action: 'submit',
          target: 'voice-recognition-stop',
          metadata: {
            source: 'voice',
            timestamp: Date.now(),
          },
        },
      })
    } catch (error) {
      const voiceError =
        error instanceof Error ? error : new Error('Failed to stop voice recognition')
      console.error('Failed to stop voice recognition:', voiceError)

      this.emit('VOICE_ERROR', {
        type: 'VOICE_ERROR',
        timestamp: Date.now(),
        payload: { error: voiceError },
      })
    }
  }
}
