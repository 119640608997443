import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/shared/components/ui/button'
import { Card } from '@/shared/components/ui/card'

export const RefillIntroPage: React.FC = () => {
  const [containerId, setContainerId] = useState<string | null>(null)
  const navigate = useNavigate()

  function simulateScan() {
    setContainerId('loreal-face-cream')
  }

  function proceed() {
    if (containerId) {
      navigate(`/refill/assess?containerId=${containerId}`)
    }
  }

  return (
    <div className='max-w-md mx-auto px-4 py-8'>
      <Card className='p-6 bg-background-secondary'>
        <h2 className='text-2xl font-bold mb-4 text-text-primary'>Refill Program</h2>
        <p className='text-text-secondary mb-6'>
          Place your reusable container on the scanner to begin the refill process.
        </p>

        <div className='space-y-4'>
          <Button
            className={`w-full ${
              containerId
                ? 'bg-background-tertiary text-text-primary border border-border-primary'
                : 'bg-brand-primary text-text-primary hover:bg-brand-secondary'
            }`}
            onClick={simulateScan}
            variant={containerId ? 'outline' : 'default'}
          >
            {containerId ? 'Container Scanned' : 'Simulate Container Scan'}
          </Button>

          {containerId && (
            <Button
              className='w-full bg-brand-primary text-text-primary hover:bg-brand-secondary'
              onClick={proceed}
            >
              Continue to Assessment
            </Button>
          )}
        </div>
      </Card>
    </div>
  )
}
