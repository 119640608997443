import { useState, useEffect } from 'react'
import { cartService } from './cart-service'
import type { CartState, AddToCartPayload, UpdateCartItemPayload } from './cart-types'

export function useCart() {
  const [state, setState] = useState<CartState>(cartService.getState())

  useEffect(() => {
    const handleCartUpdate = () => {
      setState(cartService.getState())
    }

    // Subscribe to cart updates
    const unsubscribe = cartService.subscribe(handleCartUpdate)

    return () => {
      unsubscribe()
    }
  }, [])

  return {
    state,
    addItem: (payload: AddToCartPayload) => cartService.addItem(payload),
    updateItem: (productId: string, updates: UpdateCartItemPayload) =>
      cartService.updateItem(productId, updates),
    removeItem: (productId: string) => cartService.removeItem(productId),
    clearCart: () => cartService.clearCart(),
  }
}
