import { RepairService } from './repair-service'
import {
  RepairAssessmentResult,
  RepairItem,
  RepairRequest,
  RepairHistory,
  RepairStats,
} from './repair-types'

export function assessRepair(productId: string): RepairAssessmentResult {
  return RepairService.assessItemForRepair(productId)
}

export function startRepair(item: RepairItem, customerNotes?: string): RepairRequest {
  return RepairService.initiateRepair(item, customerNotes)
}

export function updateRepairStatus(
  requestId: string,
  status: RepairRequest['status'],
  notes?: string
): RepairRequest {
  return RepairService.updateRepairStatus(requestId, status, notes)
}

export function getRepairRequest(requestId: string): RepairRequest | undefined {
  return RepairService.getRepairRequest(requestId)
}

export function getRepairHistory(requestId: string): RepairHistory | undefined {
  return RepairService.getRepairHistory(requestId)
}

export function getRepairStats(): RepairStats {
  return RepairService.getRepairStats()
}

// Helper function to check if a repair request exists and is in a specific status
export function isRepairInStatus(requestId: string, status: RepairRequest['status']): boolean {
  const request = RepairService.getRepairRequest(requestId)
  return request?.status === status
}

// Helper function to get estimated completion date for a repair
export function getEstimatedCompletion(requestId: string): Date | undefined {
  const request = RepairService.getRepairRequest(requestId)
  return request?.estimatedCompletionDate
}

// Helper function to get all status updates for a repair
export function getRepairStatusHistory(
  requestId: string
): RepairHistory['statusUpdates'] | undefined {
  const history = RepairService.getRepairHistory(requestId)
  return history?.statusUpdates
}

// Helper function to calculate repair progress percentage
export function calculateRepairProgress(requestId: string): number {
  const request = RepairService.getRepairRequest(requestId)
  if (!request) return 0

  const statusWeights = {
    pending: 0,
    in_progress: 50,
    completed: 100,
    cancelled: 0,
  }

  return statusWeights[request.status]
}

// Helper function to get repair cost breakdown
export function getRepairCostBreakdown(item: RepairItem): {
  baseCost: number
  materialsCost: number
  laborCost: number
  total: number
} {
  const baseCost = item.repairCost * 0.2
  const materialsCost = item.repairCost * 0.5
  const laborCost = item.repairCost * 0.3

  return {
    baseCost,
    materialsCost,
    laborCost,
    total: item.repairCost,
  }
}
