import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Card } from '@/shared/components/ui/card'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import { Help } from '@/assets/icons/Icons'
import { KioskCard } from '@/components/Kiosk/KioskCard'

const CardData = [
  {
    title: 'Shop',
    description: 'Browse our latest collection.',
    icon: 'ShoppingBag',
    to: '/shop/products',
  },
  {
    title: 'Return',
    description: 'Easy returns and recycling.',
    icon: 'Iteration',
    to: '/return',
  },
  {
    title: 'Repair',
    description: "Extend your shoe's life.",
    icon: 'FadingCircle',
    to: '/repair',
  },
]

export const HomePage: React.FC = () => {
  // TODO: Replace with actual user context
  const isAuthenticated = false
  const userName = 'Guest'

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      {/* Content */}
      <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-end'>
        <KioskButton>
          <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
          <div className='text-lg font-light'>Hey, Phil</div>
        </KioskButton>
      </div>
      <img src={OnLogo} alt='On Logo' className='w-16 h-16 mb-4' />
      <h1 className='text-[2.5rem] font-medium font-manrope text-white'>Welcome to On</h1>
      <p className='text-lg font-light text-text-secondary w-full text-center border-b border-white/50 pb-12'>
        The future of sustainable footwear
      </p>
      <p className='text-2xl sm-text-3xl font-light pt-12 pb-4 text-center'>
        What would you like to do today?
      </p>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 w-fit mx-auto'>
        {CardData.map(card => (
          <KioskCard
            key={card.title}
            title={card.title}
            description={card.description}
            icon={card.icon}
            to={card.to}
          />
        ))}
      </div>
      <div className='fixed z-20 bottom-0 left-0 w-screen flex justify-end p-8'>
        <KioskButton>
          <Help className='w-8 h-8' />
        </KioskButton>
      </div>
    </div>
    // <div className='w-full min-h-screen flex flex-col bg-white dark:bg-gray-900 font-sans text-gray-900 dark:text-gray-100 overflow-hidden'>
    //   {/* Hero Section */}
    //   <div className='relative flex-1'>
    //     <div className='absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900/70 to-gray-900/30 dark:from-gray-800 dark:via-gray-800/70 dark:to-gray-800/30' />

    //     <div className='relative z-10 p-6 flex flex-col justify-center h-full w-full max-w-md mx-auto text-center'>
    //       <h1 className='text-4xl font-bold text-white mb-4'>
    //         {isAuthenticated ? `Welcome back, ${userName}` : 'Welcome to On Cloud'}
    //       </h1>
    //       <p className='text-xl text-gray-300 mt-2'>
    //         {isAuthenticated
    //           ? 'How can we help you today?'
    //           : 'Experience the future of sustainable footwear'}
    //       </p>

    //       {!isAuthenticated && (
    //         <Button
    //           size='lg'
    //           className='mt-8 bg-white text-gray-900 hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700'
    //           onClick={() => {
    //             /* TODO: Implement QR scan */
    //           }}
    //         >
    //           Get Started
    //         </Button>
    //       )}
    //     </div>
    //   </div>

    //   {/* Action Tiles Section */}
    //   <div className='relative z-20 bg-white dark:bg-gray-900 py-12'>
    //     <h2 className='text-2xl font-semibold text-center mb-8 text-gray-900 dark:text-gray-100'>
    //       What would you like to do?
    //     </h2>
    //     <div className='max-w-4xl mx-auto px-4 grid grid-cols-2 gap-6'>
    //       <ActionTile
    //         to='/products'
    //         title='Shop'
    //         description='Browse our latest collection'
    //         color='bg-emerald-100 dark:bg-emerald-900'
    //         textColor='text-emerald-900 dark:text-emerald-100'
    //         icon='🛍️'
    //       />
    //       <ActionTile
    //         to='/return'
    //         title='Return'
    //         description='Easy returns and recycling'
    //         color='bg-blue-100 dark:bg-blue-900'
    //         textColor='text-blue-900 dark:text-blue-100'
    //         icon='♻️'
    //       />
    //       <ActionTile
    //         to='/repair'
    //         title='Repair'
    //         description="Extend your shoe's life"
    //         color='bg-purple-100 dark:bg-purple-900'
    //         textColor='text-purple-900 dark:text-purple-100'
    //         icon='🔧'
    //       />
    //       <ActionTile
    //         to='/refill'
    //         title='Refill'
    //         description='Sustainable solutions'
    //         color='bg-amber-100 dark:bg-amber-900'
    //         textColor='text-amber-900 dark:text-amber-100'
    //         icon='🔄'
    //       />
    //     </div>
    //   </div>

    //   {/* Recommendations Section */}
    //   {isAuthenticated && (
    //     <div className='relative z-20 py-12 bg-gray-50 dark:bg-gray-800'>
    //       <h2 className='text-2xl font-semibold text-center mb-8 text-gray-900 dark:text-gray-100'>
    //         Recommended for You
    //       </h2>
    //       <div className='max-w-2xl mx-auto px-4'>
    //         <RecommendedProductCard
    //           name='Cloudmonster'
    //           price={169.99}
    //           image='https://placehold.co/400x300/e2e8f0/1e293b.png?text=Cloudmonster'
    //         />
    //         <RecommendedProductCard
    //           name='Cloudflow'
    //           price={159.99}
    //           image='https://placehold.co/400x300/e2e8f0/1e293b.png?text=Cloudflow'
    //         />
    //       </div>
    //     </div>
    //   )}

    //   {/* Voice Command / Footer */}
    //   <div className='p-6 bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-700 flex justify-center items-center gap-6'>
    //     <Button
    //       size='lg'
    //       className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
    //       onClick={() => {
    //         /* TODO: Implement voice command */
    //       }}
    //     >
    //       Voice Command
    //     </Button>
    //     <NavLink
    //       to='/help'
    //       className='text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 text-lg'
    //     >
    //       Need Help?
    //     </NavLink>
    //   </div>
    // </div>
  )
}

// Sub-components
interface ActionTileProps {
  to: string
  title: string
  description: string
  color: string
  textColor: string
  icon: string
}

const ActionTile: React.FC<ActionTileProps> = ({
  to,
  title,
  description,
  color,
  textColor,
  icon,
}) => (
  <NavLink to={to}>
    <motion.div
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      whileHover={{ scale: 1.02 }}
      transition={{ duration: 0.2 }}
      className={`rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow ${color}`}
    >
      <Card
        className={`flex flex-col items-center justify-center p-8 bg-transparent border-0 ${textColor}`}
      >
        <span className='text-4xl mb-4'>{icon}</span>
        <h3 className='font-semibold text-2xl mb-2'>{title}</h3>
        <p className='text-lg opacity-90 text-center'>{description}</p>
      </Card>
    </motion.div>
  </NavLink>
)

interface RecommendedProductCardProps {
  name: string
  price: number
  image: string
}

const RecommendedProductCard: React.FC<RecommendedProductCardProps> = ({ name, price, image }) => (
  <motion.div
    initial={{ y: 20, opacity: 0 }}
    whileInView={{ y: 0, opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.3 }}
  >
    <Card className='flex items-center gap-6 mb-4 p-4 bg-white dark:bg-gray-800 hover:shadow-lg transition-shadow'>
      <img src={image} alt={name} className='w-24 h-24 object-cover rounded-lg' />
      <div className='flex-1'>
        <h4 className='font-semibold text-xl text-gray-900 dark:text-gray-100'>{name}</h4>
        <p className='text-gray-600 dark:text-gray-300 text-lg'>${price.toFixed(2)}</p>
      </div>
      <NavLink
        to={`/shop/products`}
        className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600 px-6 py-2 rounded-lg text-lg font-semibold transition-colors'
      >
        View
      </NavLink>
    </Card>
  </motion.div>
)
