import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import { useReturnProcess } from './use-cases'
import type { ReturnMethod } from '@/domain/retail/returns/return-types'

export const ReturnSuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const requestId = searchParams.get('requestId') || ''
  const method = searchParams.get('method') as ReturnMethod
  const value = Number(searchParams.get('value') || 0)
  const navigate = useNavigate()
  const { finalizeReturn } = useReturnProcess()

  useEffect(() => {
    if (requestId) {
      finalizeReturn(requestId).catch(console.error)
    }
  }, [requestId])

  return (
    <div className='max-w-2xl mx-auto px-4 py-8'>
      <Card className='p-6 bg-white dark:bg-gray-800'>
        <div className='text-center mb-8'>
          <div className='w-16 h-16 bg-emerald-100 dark:bg-emerald-900 rounded-full flex items-center justify-center mx-auto mb-4'>
            <svg
              className='w-8 h-8 text-emerald-600 dark:text-emerald-400'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M5 13l4 4L19 7'
              />
            </svg>
          </div>
          <h2 className='text-3xl font-bold mb-2 text-gray-900 dark:text-gray-50'>
            Return Successful!
          </h2>
          <p className='text-gray-800 dark:text-gray-200 text-lg'>
            Thank you for your return. Your{' '}
            {method === 'storeCredit' ? 'store credit' : 'loyalty points'} have been issued.
          </p>
        </div>

        <div className='bg-gray-50 dark:bg-gray-700 rounded-lg p-6 mb-8 border border-gray-200 dark:border-gray-600'>
          <h3 className='text-xl font-bold mb-4 text-gray-900 dark:text-gray-50'>Return Summary</h3>
          <div className='space-y-3'>
            <div className='flex justify-between items-center'>
              <span className='text-gray-700 dark:text-gray-300'>Return ID:</span>
              <span className='font-semibold text-gray-900 dark:text-gray-50'>{requestId}</span>
            </div>
            <div className='flex justify-between items-center'>
              <span className='text-gray-700 dark:text-gray-300'>Return Method:</span>
              <span className='font-semibold text-gray-900 dark:text-gray-50'>
                {method === 'storeCredit' ? 'Store Credit' : 'Loyalty Points'}
              </span>
            </div>
            <div className='flex justify-between items-center'>
              <span className='text-gray-700 dark:text-gray-300'>Value:</span>
              <span className='font-bold text-gray-900 dark:text-gray-50 text-lg'>
                {method === 'storeCredit'
                  ? `$${value.toFixed(2)}`
                  : `${value.toLocaleString()} points`}
              </span>
            </div>
          </div>
        </div>

        <div className='space-y-4'>
          {method === 'storeCredit' && (
            <div className='p-4 bg-emerald-50 dark:bg-emerald-900 rounded-lg border border-emerald-200 dark:border-emerald-800'>
              <p className='text-emerald-800 dark:text-emerald-100 text-lg'>
                Your store credit has been added to your account and is ready to use on your next
                purchase.
              </p>
            </div>
          )}

          {method === 'points' && (
            <div className='p-4 bg-purple-50 dark:bg-purple-900 rounded-lg border border-purple-200 dark:border-purple-800'>
              <p className='text-purple-800 dark:text-purple-100 text-lg'>
                Your loyalty points have been credited to your account. Check your rewards dashboard
                to see your updated balance.
              </p>
            </div>
          )}
        </div>

        <div className='flex justify-center mt-8 space-x-4'>
          <Button
            variant='outline'
            onClick={() => navigate('/products')}
            className='text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700'
          >
            Continue Shopping
          </Button>
          <Button
            onClick={() => navigate('/')}
            className='bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
          >
            Return Home
          </Button>
        </div>
      </Card>
    </div>
  )
}
