import { returnService } from './return-service'
import type {
  ReturnRequest,
  ReturnAssessmentResult,
  ReturnMethod,
  ReturnHistory,
} from './return-types'

export async function assessForReturn(productId: string): Promise<ReturnAssessmentResult> {
  return returnService.assessReturn(productId)
}

export async function startReturn(productId: string, method: ReturnMethod): Promise<ReturnRequest> {
  return returnService.initiateReturn(productId, method)
}

export async function completeReturn(requestId: string): Promise<ReturnRequest> {
  return returnService.completeReturn(requestId)
}

export function getReturnDetails(requestId: string): {
  request?: ReturnRequest
  history?: ReturnHistory
} {
  return {
    request: returnService.getRequest(requestId),
    history: {
      events: [], // TODO: Implement return history tracking
    },
  }
}
