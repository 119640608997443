import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import { useReturnAssessment } from './use-cases'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import { Help, InlineCheck, Return } from '@/assets/icons/Icons'
import OnIcon from '@/assets/logos/onIcon.svg'
import { KioskCard } from '@/components/Kiosk/KioskCard'

export const ReturnAssessmentPage: React.FC = () => {
  const { assessment, isAssessing, error, assessItemForReturn } = useReturnAssessment()
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId') || ''
  const navigate = useNavigate()
  const [selectedMethod, setSelectedMethod] = useState<'storeCredit' | 'points' | null>(null)

  useEffect(() => {
    if (productId) {
      assessItemForReturn(productId)
    }
  }, [productId])

  const CardData = [
    {
      title: '$25.00',
      description: 'To spend online or in-store.',
      icon: 'CreditCard',
      onClick: () => setSelectedMethod('storeCredit'),
      preTitle: 'e-Gift Card',
    },
    {
      title: '450',
      description: 'Added to your current loyalty balance.',
      icon: 'Diamond',
      onClick: () => setSelectedMethod('points'),
      preTitle: 'Loyalty Points',
    },
  ]

  if (error) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8'>
        <Card className='p-6 bg-background-secondary'>
          <h2 className='text-2xl font-bold mb-4 text-status-error'>Error</h2>
          <p className='text-text-secondary mb-6'>{error}</p>
          <Button
            onClick={() => navigate('/return')}
            className='bg-brand-primary text-text-primary hover:bg-brand-secondary'
          >
            Try Again
          </Button>
        </Card>
      </div>
    )
  }

  if (isAssessing) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8'>
        <Card className='p-6 bg-background-secondary'>
          <div className='text-center'>
            <div className='w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4'></div>
            <p className='text-gray-600 dark:text-gray-300'>Assessing your item...</p>
          </div>
        </Card>
      </div>
    )
  }

  if (!assessment) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8'>
        <Card className='p-6 bg-background-secondary'>
          <h2 className='text-2xl font-bold mb-4 text-text-primary'>Return Assessment</h2>
          <p className='text-text-secondary mb-6'>Unable to assess item. Please try again.</p>
          <Button
            onClick={() => navigate('/return')}
            className='bg-brand-primary text-text-primary hover:bg-brand-secondary'
          >
            Return Home
          </Button>
        </Card>
      </div>
    )
  }

  if (!assessment.isEligible) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8'>
        <Card className='p-6 bg-red-50 dark:bg-red-900'>
          <h2 className='text-xl font-semibold mb-4 text-gray-900 dark:text-gray-50'>
            Return Assessment
          </h2>
          <p className='text-red-800 dark:text-red-200 mb-6'>{assessment.message}</p>
          <Button
            onClick={() => navigate('/')}
            className='w-full bg-red-600 hover:bg-red-700 text-white'
          >
            Return Home
          </Button>
        </Card>
      </div>
    )
  }

  function chooseMethod(method: 'storeCredit' | 'points') {
    if (!assessment) return
    const value = method === 'storeCredit' ? assessment.storeCreditValue : assessment.pointsValue
    navigate(`/return/confirm?productId=${assessment.productId}&method=${method}&value=${value}`)
  }

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      {/* Content */}
      <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-between'>
        <div className='flex items-center gap-4'>
          <img
            src={OnIcon}
            alt='ON Logo'
            className='w-[48px] h-[48px]'
            onClick={() => navigate('/')}
          />
        </div>
        <KioskButton>
          <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
          <div className='text-lg font-light'>Hey, Phil</div>
        </KioskButton>
      </div>
      <img src={OnLogo} alt='On Logo' className='w-16 h-16 mb-4' />
      <h1 className='text-[2.5rem] font-medium font-manrope text-white'>Return Assessment</h1>
      <p className='text-lg text-[#3AFFBD] font-light w-full text-center border-b border-white/50 pb-12'>
        Your product is eligible for return{' '}
      </p>
      <p className='text-2xl sm-text-3xl font-light pt-12 pb-4 text-center'>
        {selectedMethod
          ? `You will receive ${
              selectedMethod === 'storeCredit' ? '$25.00 in store credit' : '450 loyalty points'
            } for this return.`
          : 'Choose your return reward'}
      </p>
      {}
      {selectedMethod === null ? (
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 w-fit mx-auto'>
          {CardData.map(card => (
            <KioskCard
              key={card.title}
              title={card.title}
              description={card.description}
              icon={card.icon}
              onClick={card.onClick}
              preTitle={card.preTitle}
            />
          ))}
        </div>
      ) : (
        <div className='bg-black text-wite  rounded-xl border border-white min-w-[300px] py-6 px-6 space-y-4 mb-8'>
          <p className='text-lg font-medium'>By proceeding, you confirm that:</p>
          <ul className='list-inside space-y-1 font-light'>
            <li className='flex gap-2 items-center'>
              <InlineCheck />
              The item is in its original condition
            </li>
            <li className='flex gap-2 items-center'>
              <InlineCheck />
              All original packaging and accessories are included
            </li>
            <li className='flex gap-2 items-center'>
              <InlineCheck />
              You understand this return is final
            </li>
          </ul>
        </div>
      )}
      {selectedMethod && (
        <KioskButton
          className='bg-yellow-500 text-black border-none py-3 px-6 hover:bg-yellow-400'
          onClick={() => navigate(`/return/confirm?method=${selectedMethod}`)}
        >
          Confirm Return
        </KioskButton>
      )}
    </div>

    // <div className='max-w-2xl mx-auto px-4 py-8'>
    //   <Card className='p-6'>
    //     <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-50'>
    //       Return Assessment
    //     </h2>
    //     <div className='p-4 bg-emerald-50 dark:bg-emerald-900 rounded-lg mb-6'>
    //       <p className='text-emerald-800 dark:text-emerald-200'>{assessment.message}</p>
    //     </div>

    //     <div className='space-y-6'>
    //       <div>
    //         <h3 className='text-lg font-semibold mb-4 text-gray-900 dark:text-gray-50'>
    //           Choose Your Return Method
    //         </h3>
    //         <div className='grid gap-4 sm:grid-cols-2'>
    //           <Card
    //             className='p-4 hover:shadow-lg transition-shadow cursor-pointer bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700'
    //             onClick={() => chooseMethod('storeCredit')}
    //           >
    //             <h4 className='font-semibold mb-2 text-gray-900 dark:text-gray-50'>Store Credit</h4>
    //             <p className='text-2xl font-bold text-green-600 dark:text-green-400'>
    //               ${assessment.storeCreditValue.toFixed(2)}
    //             </p>
    //             <p className='text-sm text-gray-600 dark:text-gray-300 mt-2'>
    //               Use towards your next purchase
    //             </p>
    //           </Card>

    //           <Card
    //             className='p-4 hover:shadow-lg transition-shadow cursor-pointer bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700'
    //             onClick={() => chooseMethod('points')}
    //           >
    //             <h4 className='font-semibold mb-2 text-gray-900 dark:text-gray-50'>
    //               Loyalty Points
    //             </h4>
    //             <p className='text-2xl font-bold text-purple-600 dark:text-purple-400'>
    //               {assessment.pointsValue.toLocaleString()} points
    //             </p>
    //             <p className='text-sm text-gray-600 dark:text-gray-300 mt-2'>
    //               Add to your loyalty balance
    //             </p>
    //           </Card>
    //         </div>
    //       </div>

    //       <div className='flex justify-end space-x-4'>
    //         <Button
    //           variant='outline'
    //           onClick={() => navigate('/return')}
    //           className='text-gray-900 dark:text-gray-100 border-gray-300 dark:border-gray-600'
    //         >
    //           Back
    //         </Button>
    //       </div>
    //     </div>
    //   </Card>
    // </div>
  )
}
