import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import { motion } from 'framer-motion'
import { Return, Help } from '@/assets/icons/Icons'
import KioskButton from '@/components/Kiosk/KioskButton'

export default function ShopLayout() {
  const navigate = useNavigate()

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>

      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      {/* Content */}
      <motion.div
        className='min-h-screen bg-white dark:bg-gray-900 py-8 rounded-xl my-20'
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, delay: 0.2 }}
      >
        <Outlet />
      </motion.div>

      <div className='fixed z-20 bottom-0 left-0 w-screen flex justify-between p-8'>
        <KioskButton className='px-6' onClick={() => navigate('/')}>
          <Return className='w-5 h-5' />
        </KioskButton>
        <KioskButton>
          <Help className='w-8 h-8' />
        </KioskButton>
      </div>
    </div>
  )
}
