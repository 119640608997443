import { cn } from '@/lib/utils'
import Lottie from 'react-lottie-player'
import OnSpinnerFile from '@/assets/animated/OnSpinner.json'
import { useRef } from 'react'

export default function OnSpinner({ className }) {
  const playerRef = useRef(null)
  return (
    <Lottie
      animationData={OnSpinnerFile}
      ref={playerRef}
      loop={true}
      speed={0.3}
      className={cn('', className)}
      play
    />
  )
}
