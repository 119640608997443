import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useRepairProcess, useRepairProgress } from './use-cases'
import { Card } from '@/shared/components/ui/card'

export const RepairSuccessPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const requestId = searchParams.get('requestId')
  const { request, loading, error, loadRepairDetails } = useRepairProcess()
  const { progress } = useRepairProgress(requestId)

  useEffect(() => {
    if (requestId) {
      loadRepairDetails(requestId)
    }
  }, [requestId])

  if (loading) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8 text-center'>
        <div className='w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin mx-auto mb-4'></div>
        <p className='text-gray-600 dark:text-gray-300'>Loading repair details...</p>
      </div>
    )
  }

  if (error || !request) {
    return (
      <div className='max-w-2xl mx-auto px-4 py-8'>
        <Card className='p-6 bg-red-50 dark:bg-red-900'>
          <p className='text-red-600 dark:text-red-100 mb-4'>
            {error || 'Unable to load repair details'}
          </p>
          <button
            onClick={() => navigate('/repair')}
            className='px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700'
          >
            Start Over
          </button>
        </Card>
      </div>
    )
  }

  return (
    <div className='max-w-2xl mx-auto px-4 py-8'>
      <div className='text-center mb-8'>
        <div className='w-16 h-16 bg-green-100 dark:bg-green-900/50 rounded-full flex items-center justify-center mx-auto mb-4'>
          <svg
            className='w-8 h-8 text-green-600 dark:text-green-400'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
          >
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M5 13l4 4L19 7' />
          </svg>
        </div>
        <h1 className='text-3xl font-bold mb-2 text-gray-900 dark:text-gray-50'>
          Repair Request Confirmed!
        </h1>
        <p className='text-gray-700 dark:text-gray-300'>
          Your repair request has been successfully submitted.
        </p>
      </div>

      <Card className='mb-6 p-6'>
        <div className='space-y-6'>
          <div>
            <h2 className='text-xl font-semibold mb-4 text-gray-900 dark:text-gray-50'>
              Request Details
            </h2>
            <div className='grid grid-cols-2 gap-4 text-sm'>
              <div>
                <p className='text-gray-700 dark:text-gray-300'>Request ID</p>
                <p className='font-medium text-gray-900 dark:text-gray-50'>{request.id}</p>
              </div>
              <div>
                <p className='text-gray-700 dark:text-gray-300'>Status</p>
                <p className='font-medium text-gray-900 dark:text-gray-50 capitalize'>
                  {request.status}
                </p>
              </div>
              <div>
                <p className='text-gray-700 dark:text-gray-300'>Estimated Cost</p>
                <p className='font-medium text-gray-900 dark:text-gray-50'>
                  ${request.item.repairCost.toFixed(2)}
                </p>
              </div>
              <div>
                <p className='text-gray-700 dark:text-gray-300'>Estimated Completion</p>
                <p className='font-medium text-gray-900 dark:text-gray-50'>
                  {request.estimatedCompletionDate.toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </p>
              </div>
            </div>
          </div>

          <div>
            <h3 className='font-semibold mb-2 text-gray-900 dark:text-gray-50'>Progress</h3>
            <div className='relative pt-1'>
              <div className='flex mb-2 items-center justify-between'>
                <div>
                  <span className='text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blue-700 bg-blue-100 dark:text-blue-100 dark:bg-blue-900/50'>
                    {request.status}
                  </span>
                </div>
                <div className='text-right'>
                  <span className='text-xs font-semibold inline-block text-blue-700 dark:text-blue-300'>
                    {progress}%
                  </span>
                </div>
              </div>
              <div className='overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-100 dark:bg-blue-900/30'>
                <div
                  style={{ width: `${progress}%` }}
                  className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-600 dark:bg-blue-500'
                ></div>
              </div>
            </div>
          </div>

          <div className='bg-blue-50 dark:bg-blue-900/50 p-4 rounded-lg'>
            <h3 className='font-semibold mb-2 text-gray-900 dark:text-gray-50'>Next Steps</h3>
            <ul className='list-disc list-inside space-y-1 text-sm text-gray-700 dark:text-gray-300'>
              <li>We'll send you updates as we progress with the repair</li>
              <li>You'll receive a notification when your item is ready</li>
              <li>Bring your request ID when picking up your item</li>
              <li>Payment will be collected upon pickup</li>
            </ul>
          </div>
        </div>
      </Card>

      <div className='space-y-3'>
        <button
          onClick={() => navigate('/')}
          className='w-full py-3 px-4 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-semibold'
        >
          Return to Home
        </button>
        <button
          onClick={() => {
            /* TODO: Implement tracking page */
          }}
          className='w-full py-3 px-4 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300 font-semibold'
        >
          Track Repair Status
        </button>
      </div>
    </div>
  )
}
