import {
  RepairAssessmentResult,
  RepairItem,
  RepairRequest,
  RepairHistory,
  RepairStats,
} from './repair-types'
import { eventBus } from '@/domain/events/event-bus'

// Mock data for demonstration
const mockRepairRequests = new Map<string, RepairRequest>()
const mockRepairHistory = new Map<string, RepairHistory>()

function generateRequestId(): string {
  return `REP-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`
}

function mockAssessItem(productId: string): RepairAssessmentResult {
  // Demo assessment logic
  const isHighEndProduct = ['cloudmonster2', 'cloudflow', 'cloudstratus'].includes(productId)
  const isRepairable = !['cloudrunner'].includes(productId)

  if (!isRepairable) {
    return {
      item: {
        productId,
        condition: 'damaged',
        repairCost: 0,
        estimatedTimeDays: 0,
        description: 'Item is beyond repair',
        priority: 'low',
        category: 'Running',
      },
      isEligible: false,
      message: 'Item is too damaged for standard repair.',
      alternativeSolutions: [
        'Consider our recycling program',
        'Check out our trade-in options',
        'View similar new products',
      ],
    }
  }

  const assessment: RepairAssessmentResult = {
    item: {
      productId,
      condition: isHighEndProduct ? 'worn' : 'damaged',
      repairCost: isHighEndProduct ? 30 : 50,
      estimatedTimeDays: isHighEndProduct ? 3 : 5,
      description: isHighEndProduct
        ? 'Minor wear and tear, suitable for repair'
        : 'Moderate damage, requires extensive repair',
      priority: isHighEndProduct ? 'high' : 'medium',
      category: 'Running',
    },
    isEligible: true,
    message: isHighEndProduct
      ? 'Item can be repaired with standard service.'
      : 'Item requires extensive repair service.',
    suggestedActions: ['Clean and sanitize', 'Replace worn parts', 'Reinforce weak points'],
  }

  return assessment
}

export class RepairService {
  static assessItemForRepair(productId: string): RepairAssessmentResult {
    const result = mockAssessItem(productId)

    eventBus.emit({
      id: generateRequestId(),
      type: 'REPAIR_ASSESSED',
      timestamp: Date.now(),
      payload: {
        productId,
        condition: result.item.condition,
        isEligible: result.isEligible,
        timestamp: Date.now(),
      },
    })

    return result
  }

  static initiateRepair(item: RepairItem, customerNotes?: string): RepairRequest {
    const requestId = generateRequestId()
    const now = new Date()

    const request: RepairRequest = {
      id: requestId,
      item,
      customerNotes,
      status: 'pending',
      createdAt: now,
      updatedAt: now,
      estimatedCompletionDate: new Date(
        now.getTime() + item.estimatedTimeDays * 24 * 60 * 60 * 1000
      ),
    }

    mockRepairRequests.set(requestId, request)

    const history: RepairHistory = {
      requestId,
      statusUpdates: [
        {
          status: 'pending',
          timestamp: now,
          notes: 'Repair request created',
        },
      ],
    }

    mockRepairHistory.set(requestId, history)

    eventBus.emit({
      id: generateRequestId(),
      type: 'REPAIR_INITIATED',
      timestamp: Date.now(),
      payload: {
        requestId: request.id,
        productId: item.productId,
        cost: item.repairCost,
        timestamp: now.getTime(),
      },
    })

    return request
  }

  static updateRepairStatus(
    requestId: string,
    status: RepairRequest['status'],
    notes?: string
  ): RepairRequest {
    const request = mockRepairRequests.get(requestId)
    if (!request) {
      throw new Error(`Repair request ${requestId} not found`)
    }

    const now = new Date()
    request.status = status
    request.updatedAt = now

    const history = mockRepairHistory.get(requestId)
    if (history) {
      history.statusUpdates.push({
        status,
        timestamp: now,
        notes,
      })
    }

    eventBus.emit({
      id: generateRequestId(),
      type: 'REPAIR_UPDATED',
      timestamp: Date.now(),
      payload: {
        requestId,
        status,
        timestamp: now.getTime(),
      },
    })

    return request
  }

  static getRepairRequest(requestId: string): RepairRequest | undefined {
    return mockRepairRequests.get(requestId)
  }

  static getRepairHistory(requestId: string): RepairHistory | undefined {
    return mockRepairHistory.get(requestId)
  }

  static getRepairStats(): RepairStats {
    const repairs = Array.from(mockRepairRequests.values())
    const completed = repairs.filter(r => r.status === 'completed')

    return {
      totalRepairs: repairs.length,
      averageRepairTime: completed.length
        ? completed.reduce((acc, r) => acc + (r.updatedAt.getTime() - r.createdAt.getTime()), 0) /
          completed.length /
          (24 * 60 * 60 * 1000)
        : 0,
      successRate: repairs.length ? (completed.length / repairs.length) * 100 : 0,
      commonIssues: [
        { issue: 'Sole wear', count: Math.floor(repairs.length * 0.4) },
        { issue: 'Stitching', count: Math.floor(repairs.length * 0.3) },
        { issue: 'Insole damage', count: Math.floor(repairs.length * 0.2) },
        { issue: 'Other', count: Math.floor(repairs.length * 0.1) },
      ],
    }
  }
}
