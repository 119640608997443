import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export * from './cn'
export * from './format'
export * from './event-emitter'
export * from './storage'
export * from './stringMatching'
export * from './testUtils'
export * from './voiceCommands'
export * from './speechRecognition'
export * from './audio-manager'
export * from './openai-tts-manager'
export * from './elevenlabs-tts-manager'
export * from './conversation'
export * from './conversationManager'
export * from './greetingManager'
export * from './greetingTemplates'
export * from './speechTemplates'
export * from './userStorage'
export * from './utils'
