import { EventEmitter } from './event-emitter'

type AudioEvent = 'play' | 'pause' | 'end' | 'error'

class AudioManager extends EventEmitter {
  private audio: HTMLAudioElement | null = null
  private isPlaying: boolean = false

  constructor() {
    super()
    if (typeof window !== 'undefined') {
      this.audio = new Audio()
      this.setupEventListeners()
    }
  }

  private setupEventListeners() {
    if (!this.audio) return

    this.audio.onplay = () => {
      this.isPlaying = true
      this.emit('play')
    }

    this.audio.onpause = () => {
      this.isPlaying = false
      this.emit('pause')
    }

    this.audio.onended = () => {
      this.isPlaying = false
      this.emit('end')
    }

    this.audio.onerror = error => {
      this.emit('error', error)
    }
  }

  async play(audioUrl: string) {
    if (!this.audio) return

    try {
      this.audio.src = audioUrl
      await this.audio.play()
    } catch (error) {
      this.emit('error', error)
    }
  }

  pause() {
    if (this.audio && this.isPlaying) {
      this.audio.pause()
    }
  }

  stop() {
    if (this.audio) {
      this.audio.pause()
      this.audio.currentTime = 0
    }
  }

  setVolume(volume: number) {
    if (this.audio) {
      this.audio.volume = Math.max(0, Math.min(1, volume))
    }
  }

  getIsPlaying() {
    return this.isPlaying
  }
}

export const audioManager = new AudioManager()
