import * as React from 'react'
import { cn } from '@/shared/utils'
import { inputStyles } from '@/shared/styles/templates/input'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: keyof typeof inputStyles.variants
  size?: keyof typeof inputStyles.sizes
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant = 'default', size = 'md', ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          inputStyles.base,
          variant && inputStyles.variants[variant],
          size && inputStyles.sizes[size],
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input }
