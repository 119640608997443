import { useState, useEffect, useCallback, useRef } from 'react'
import { VoiceProcessor } from '../VoiceProcessor'
import { VoiceCommand, VoiceEvent, VoiceCommandPayload, VoiceErrorPayload } from '../types'

interface VoiceProcessorHookState {
  isListening: boolean
  transcript: string
  confidence: number
  error?: Error
  lastCommand?: VoiceCommand
}

export function useVoiceProcessor() {
  const [state, setState] = useState<VoiceProcessorHookState>({
    isListening: false,
    transcript: '',
    confidence: 0,
  })

  const processor = useRef<VoiceProcessor | null>(null)

  useEffect(() => {
    processor.current = new VoiceProcessor()
    return () => {
      if (processor.current) {
        processor.current.stop()
      }
    }
  }, [])

  useEffect(() => {
    if (!processor.current) return

    const handleTranscript = (event: VoiceEvent<'TRANSCRIPT_UPDATE'>) => {
      const { transcript, confidence } = event.payload
      setState(prev => ({
        ...prev,
        transcript,
        confidence,
      }))
    }

    const handleCommand = (event: VoiceEvent<'VOICE_COMMAND'>) => {
      const { command } = event.payload as VoiceCommandPayload
      if (command) {
        setState(prev => ({
          ...prev,
          lastCommand: command,
        }))
      }
    }

    const handleError = (event: VoiceEvent<'VOICE_ERROR'>) => {
      const { error } = event.payload as VoiceErrorPayload
      setState(prev => ({
        ...prev,
        error,
        isListening: false,
      }))
    }

    processor.current.on('TRANSCRIPT_UPDATE', handleTranscript)
    processor.current.on('VOICE_COMMAND', handleCommand)
    processor.current.on('VOICE_ERROR', handleError)

    return () => {
      if (processor.current) {
        processor.current.off('TRANSCRIPT_UPDATE', handleTranscript)
        processor.current.off('VOICE_COMMAND', handleCommand)
        processor.current.off('VOICE_ERROR', handleError)
      }
    }
  }, [])

  const startListening = useCallback(async () => {
    if (!processor.current) return
    try {
      await processor.current.start()
      setState(prev => ({ ...prev, isListening: true }))
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error as Error,
        isListening: false,
      }))
      throw error
    }
  }, [])

  const stopListening = useCallback(() => {
    if (!processor.current) return
    processor.current.stop()
    setState(prev => ({ ...prev, isListening: false }))
  }, [])

  const clearError = useCallback(() => {
    setState(prev => ({ ...prev, error: undefined }))
  }, [])

  const clearTranscript = useCallback(() => {
    setState(prev => ({ ...prev, transcript: '' }))
  }, [])

  const clearLastCommand = useCallback(() => {
    setState(prev => ({ ...prev, lastCommand: undefined }))
  }, [])

  const reset = useCallback(() => {
    setState({
      isListening: false,
      transcript: '',
      confidence: 0,
    })
  }, [])

  return {
    ...state,
    startListening,
    stopListening,
    clearError,
    clearTranscript,
    clearLastCommand,
    reset,
  }
}
