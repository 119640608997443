import {
  RefillAssessmentResult,
  RefillOptions,
  RefillRequest,
  RefillHistory,
  RefillDeliveryMethod,
  RefillRequestStatus,
} from './refill-types'
import { eventBus } from '@/domain/events/event-bus'
import { generateId } from '@/shared/utils/id-generator'

// Mock data storage
const mockRefillRequests = new Map<string, RefillRequest>()
const mockRefillHistory = new Map<string, RefillHistory>()

function mockAssessRefill(containerId: string): RefillAssessmentResult {
  // Mock logic: If containerId matches known product, it's eligible
  if (containerId === 'loreal-face-cream') {
    return {
      productId: 'loreal-face-cream-refill',
      containerId,
      isEligible: true,
      refillCost: 25,
      estimatedTimeDays: 2,
      message: "Container recognized. You can refill your L'Oréal face cream.",
    }
  }
  return {
    productId: '',
    containerId,
    isEligible: false,
    refillCost: 0,
    estimatedTimeDays: 0,
    message: 'Container not recognized or not eligible for refill.',
  }
}

function mockRefillOptions(): RefillOptions {
  return {
    deliveryMethods: ['inStore', 'home'],
    subscriptionAvailable: true,
    subscriptionDiscountRate: 0.1, // 10% off if subscription chosen
  }
}

export class RefillService {
  private requests: RefillRequest[] = []

  static assessContainer(containerId: string): RefillAssessmentResult {
    const result = mockAssessRefill(containerId)
    eventBus.emit({
      id: generateId(),
      type: 'REFILL_ASSESSED',
      timestamp: Date.now(),
      payload: {
        containerId,
        isEligible: result.isEligible,
        productId: result.productId,
        refillCost: result.refillCost,
      },
    })
    return result
  }

  static getRefillOptions(): RefillOptions {
    return mockRefillOptions()
  }

  static initiateRefill(
    productId: string,
    method: RefillDeliveryMethod,
    subscription: boolean,
    cost: number
  ): RefillRequest {
    const requestId = generateId()
    const now = new Date()

    const request: RefillRequest = {
      id: requestId,
      productId,
      method,
      subscription,
      cost,
      status: 'pending',
      createdAt: now,
      updatedAt: now,
    }

    mockRefillRequests.set(requestId, request)

    const history: RefillHistory = {
      requestId,
      statusUpdates: [
        {
          status: 'pending',
          timestamp: now,
          notes: 'Refill request initiated',
        },
      ],
    }

    mockRefillHistory.set(requestId, history)

    eventBus.emit({
      id: generateId(),
      type: 'REFILL_INITIATED',
      timestamp: Date.now(),
      payload: {
        productId,
        method,
        subscription,
        cost,
      },
    })

    return request
  }

  static completeRefill(requestId: string): RefillRequest {
    const request = mockRefillRequests.get(requestId)
    if (!request) {
      throw new Error(`Refill request ${requestId} not found`)
    }

    const now = new Date()
    request.status = 'completed'
    request.updatedAt = now

    const history = mockRefillHistory.get(requestId)
    if (history) {
      history.statusUpdates.push({
        status: 'completed',
        timestamp: now,
        notes: 'Refill completed successfully',
      })
    }

    eventBus.emit({
      id: generateId(),
      type: 'REFILL_COMPLETED',
      timestamp: Date.now(),
      payload: {
        productId: request.productId,
      },
    })

    return request
  }

  static getRefillRequest(requestId: string): RefillRequest | undefined {
    return mockRefillRequests.get(requestId)
  }

  static getRefillHistory(requestId: string): RefillHistory | undefined {
    return mockRefillHistory.get(requestId)
  }

  async updateRefillRequest(
    requestId: string,
    updates: {
      status?: RefillRequestStatus
      method?: RefillDeliveryMethod
      subscription?: boolean
    }
  ): Promise<RefillRequest> {
    const request = mockRefillRequests.get(requestId)
    if (!request) {
      throw new Error('Refill request not found')
    }

    if (updates.status) {
      request.status = updates.status
    }
    if (updates.method) {
      request.method = updates.method
    }
    if (updates.subscription !== undefined) {
      request.subscription = updates.subscription
    }

    request.updatedAt = new Date()

    eventBus.emit({
      id: generateId(),
      type: 'REFILL_UPDATED',
      timestamp: Date.now(),
      payload: {
        requestId,
        status: request.status,
        deliveryMethod: request.method,
        subscription: request.subscription,
      },
    })

    return request
  }
}
