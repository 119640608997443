import type { SystemEvent, EventMap } from './types'

// Add repair events to EventMap
declare module './types' {
  interface EventMap {
    REPAIR_ASSESSED: {
      productId: string
      condition: string
      isEligible: boolean
      timestamp: number
    }
    REPAIR_INITIATED: {
      requestId: string
      productId: string
      cost: number
      timestamp: number
    }
    REPAIR_UPDATED: {
      requestId: string
      status: string
      timestamp: number
    }
  }
}

export type EventListener<T extends keyof EventMap> = (event: SystemEvent<T>) => void

export interface EventBus {
  emit: (payload: SystemEvent<keyof EventMap>) => void
  listen: (listener: EventListener<keyof EventMap>) => () => void
}

class EventBusImpl implements EventBus {
  private listeners: Set<EventListener<keyof EventMap>> = new Set()

  emit(payload: SystemEvent<keyof EventMap>): void {
    this.listeners.forEach(listener => {
      try {
        listener(payload)
      } catch (error) {
        console.error('Error in event listener:', error)
      }
    })
  }

  listen(listener: EventListener<keyof EventMap>): () => void {
    this.listeners.add(listener)
    return () => {
      this.listeners.delete(listener)
    }
  }
}

// Create singleton instance
export const eventBus: EventBus = new EventBusImpl()
