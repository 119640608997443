import { RefillService } from './refill-service'
import type {
  RefillAssessmentResult,
  RefillOptions,
  RefillRequest,
  RefillDeliveryMethod,
} from './refill-types'

export function assessRefill(containerId: string): RefillAssessmentResult {
  return RefillService.assessContainer(containerId)
}

export function fetchRefillOptions(): RefillOptions {
  return RefillService.getRefillOptions()
}

export function startRefill(
  productId: string,
  method: RefillDeliveryMethod,
  subscription: boolean,
  cost: number
): RefillRequest {
  return RefillService.initiateRefill(productId, method, subscription, cost)
}

export function finishRefill(requestId: string): RefillRequest {
  return RefillService.completeRefill(requestId)
}

export function getRefillDetails(requestId: string): RefillRequest | undefined {
  return RefillService.getRefillRequest(requestId)
}

export function getRefillHistory(requestId: string) {
  return RefillService.getRefillHistory(requestId)
}
