import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'

interface ToastProps {
  title: string
  description?: string
  variant?: 'default' | 'destructive'
  duration?: number
  onClose?: () => void
}

export const Toast: React.FC<ToastProps> = ({
  title,
  description,
  variant = 'default',
  duration = 3000,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
      onClose?.()
    }, duration)

    return () => clearTimeout(timer)
  }, [duration, onClose])

  if (!isVisible) return null

  const bgColor = variant === 'destructive' ? 'bg-red-500' : 'bg-emerald-500'

  return (
    <div className='fixed bottom-4 right-4 z-50 animate-fade-in'>
      <div className={`${bgColor} text-white px-6 py-3 rounded-lg shadow-lg`}>
        <div className='font-semibold'>{title}</div>
        {description && <div className='text-sm mt-1'>{description}</div>}
      </div>
    </div>
  )
}

type ToastVariant = 'default' | 'destructive'

interface ToastOptions {
  title: string
  description?: string
  variant?: ToastVariant
  duration?: number
}

class ToastManager {
  private static container: HTMLDivElement | null = null
  private static toastId = 0

  private static createContainer() {
    if (typeof document === 'undefined') return

    const container = document.createElement('div')
    container.id = 'toast-container'
    document.body.appendChild(container)
    this.container = container
  }

  private static getContainer() {
    if (!this.container) {
      this.createContainer()
    }
    return this.container
  }

  static show(options: ToastOptions) {
    const id = ++this.toastId
    const container = this.getContainer()
    if (!container) return

    const toastElement = document.createElement('div')
    toastElement.id = `toast-${id}`
    container.appendChild(toastElement)

    const removeToast = () => {
      const element = document.getElementById(`toast-${id}`)
      if (element) {
        element.remove()
      }
    }

    const props: ToastProps = {
      title: options.title,
      description: options.description,
      variant: options.variant || 'default',
      duration: options.duration || 3000,
      onClose: removeToast,
    }

    const root = createRoot(toastElement)
    root.render(<Toast {...props} />)

    return id
  }

  static error(options: Omit<ToastOptions, 'variant'>) {
    return this.show({ ...options, variant: 'destructive' })
  }

  static success(options: Omit<ToastOptions, 'variant'>) {
    return this.show({ ...options, variant: 'default' })
  }
}

export const toast = ToastManager
