import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Icons from '@/assets/icons/Icons'

const IconMap: Record<string, React.FC<{ className?: string }>> = {
  ShoppingBag: Icons.ShoppingBag,
  Iteration: Icons.Iteration,
  FadingCircle: Icons.FadingCircle,
  CreditCard: Icons.CreditCard,
  Diamond: Icons.Diamond,
}

interface KioskCardProps {
  title: string
  description: string
  preTitle?: string
  icon: string
  to?: string
  onClick?: () => void
}

export const KioskCard: React.FC<KioskCardProps> = ({
  title,
  description,
  preTitle,
  icon,
  to,
  onClick,
}) => {
  const IconComponent = IconMap[icon]

  const navigate = useNavigate()

  return (
    <div
      className='w-full h-full rounded-xl border border-white min-w-[300px] bg-black cursor-pointer hover:bg-[#0e0e0e] transition-all duration-300'
      onClick={() => {
        to && navigate(to)
        onClick && onClick()
      }}
    >
      <div className='flex flex-col items-start justify-center p-6 bg-transparent border-0'>
        <IconComponent className='w-12 h-12 mb-6' />
        {preTitle && <p className='font-light text-text-secondary mb-2'>{preTitle}</p>}
        <h3 className='text-2xl mb-2'>{title}</h3>
        <p className='font-light text-text-secondary'>{description}</p>
      </div>
    </div>
  )
}
