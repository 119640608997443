import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useRefillOptions } from './use-cases'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import type { RefillDeliveryMethod } from '@/domain/retail/refills/refill-types'

export const RefillOptionsPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId') || ''
  const baseCost = Number(searchParams.get('cost') || 0)
  const navigate = useNavigate()

  const { options, loading, error, loadOptions } = useRefillOptions(productId)
  const [method, setMethod] = useState<RefillDeliveryMethod>('inStore')
  const [subscription, setSubscription] = useState(false)

  useEffect(() => {
    if (productId) {
      loadOptions()
    }
  }, [productId])

  if (loading) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <p className='text-center text-gray-600 dark:text-gray-300'>Loading refill options...</p>
        </Card>
      </div>
    )
  }

  if (error) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-50'>Error</h2>
          <p className='text-red-600 dark:text-red-400 mb-6'>{error}</p>
          <Button
            className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            onClick={() => navigate('/refill')}
          >
            Try Again
          </Button>
        </Card>
      </div>
    )
  }

  if (!options) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <p className='text-center text-gray-600 dark:text-gray-300 mb-6'>
            No refill options available.
          </p>
          <Button
            className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            onClick={() => navigate('/refill')}
          >
            Go Back
          </Button>
        </Card>
      </div>
    )
  }

  let finalCost = baseCost
  if (subscription && options.subscriptionAvailable && options.subscriptionDiscountRate) {
    finalCost = baseCost * (1 - options.subscriptionDiscountRate)
  }

  return (
    <div className='max-w-md mx-auto px-4 py-8'>
      <Card className='p-6 bg-white dark:bg-gray-800'>
        <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-50'>Refill Options</h2>
        <p className='text-gray-700 dark:text-gray-300 mb-6'>
          Choose your delivery method and subscription preference.
        </p>

        <div className='space-y-6'>
          <div>
            <h3 className='text-lg font-semibold mb-3 text-gray-900 dark:text-gray-50'>
              Delivery Method
            </h3>
            <div className='grid grid-cols-2 gap-4'>
              {options.deliveryMethods.map(m => (
                <Button
                  key={m}
                  variant={method === m ? 'default' : 'outline'}
                  onClick={() => setMethod(m)}
                  className={
                    method === m
                      ? 'w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
                      : 'w-full border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-700'
                  }
                >
                  {m === 'inStore' ? 'In-Store Pickup' : 'Home Delivery'}
                </Button>
              ))}
            </div>
          </div>

          {options.subscriptionAvailable && (
            <div>
              <h3 className='text-lg font-semibold mb-3 text-gray-900 dark:text-gray-50'>
                Subscription
              </h3>
              <div className='bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg border border-gray-200 dark:border-gray-600'>
                <label className='flex items-center justify-between cursor-pointer'>
                  <div>
                    <span className='text-gray-900 dark:text-gray-50 font-medium'>
                      Subscribe & Save
                    </span>
                    <p className='text-sm text-gray-600 dark:text-gray-300'>
                      Save {((options.subscriptionDiscountRate ?? 0) * 100).toFixed(0)}% on
                      recurring refills
                    </p>
                  </div>
                  <input
                    type='checkbox'
                    checked={subscription}
                    onChange={e => setSubscription(e.target.checked)}
                    className='h-5 w-5 rounded border-gray-300 text-emerald-600 focus:ring-emerald-500 dark:border-gray-600 dark:bg-gray-700'
                  />
                </label>
              </div>
            </div>
          )}

          <div className='border-t border-gray-200 dark:border-gray-600 pt-4'>
            <div className='flex justify-between items-center mb-6'>
              <span className='text-lg font-semibold text-gray-900 dark:text-gray-50'>
                Total Cost
              </span>
              <span className='text-lg font-bold text-gray-900 dark:text-gray-50'>
                ${finalCost.toFixed(2)}
              </span>
            </div>

            <div className='space-y-4'>
              <Button
                className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
                onClick={() =>
                  navigate(
                    `/refill/confirm?productId=${productId}&method=${method}&subscription=${subscription}&cost=${finalCost}`
                  )
                }
              >
                Confirm Options
              </Button>
              <Button
                className='w-full border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-700'
                variant='outline'
                onClick={() => navigate('/refill')}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}
