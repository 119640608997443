export const inputStyles = {
  base: 'flex w-full rounded-md border bg-background text-foreground ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  variants: {
    default: 'border-input',
    ghost: 'border-transparent bg-transparent',
    outline: 'border-input hover:border-accent',
    filled: 'border-transparent bg-muted',
    error: 'border-destructive',
    success: 'border-success',
  },
  sizes: {
    sm: 'h-8 px-2 text-sm',
    md: 'h-10 px-3 text-base',
    lg: 'h-12 px-4 text-lg',
  },
  states: {
    disabled: 'opacity-50 cursor-not-allowed',
    readOnly: 'cursor-default',
    error: 'border-destructive focus-visible:ring-destructive',
    success: 'border-success focus-visible:ring-success',
  },
}
