import React from 'react'
import { Outlet } from 'react-router-dom'
import { VoiceEnabledLayout } from '../../shared/components/layouts/voice-enabled-layout'
import { AIAction } from '../../domain/retail/use-cases/conversations/types'

export function KioskLayout() {
  const handleVoiceCommand = (text: string, action: AIAction) => {
    console.log('Voice command received:', { text, action })
    // Handle the voice command and action here
  }

  return (
    <VoiceEnabledLayout onVoiceCommand={handleVoiceCommand}>
      {/* <div className='flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900'>
        <nav className='bg-white dark:bg-gray-800 shadow-sm'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='flex justify-between h-16'>
              <div className='flex'>
                <div className='flex-shrink-0 flex items-center'>
                  <img className='h-8 w-auto' src='/logo.png' alt='Logo' />
                </div>
              </div>
            </div>
          </div>
        </nav>
        <main className='flex-1'>
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8'>
            <Outlet />
          </div>
        </main>
      </div> */}
      <div className='flex flex-col min-h-screen font-manrope'>
        <Outlet />
      </div>
    </VoiceEnabledLayout>
  )
}
