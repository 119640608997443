import { motion } from 'framer-motion'
import { cn } from '@/shared/utils/cn'

interface VoiceWaveformProps {
  isActive: boolean
  intensity?: number // 0 to 1
  color?: string
  className?: string
}

export function VoiceWaveform({
  isActive,
  intensity = 0.5,
  color = '#3B82F6', // Default blue
  className,
}: VoiceWaveformProps) {
  // Generate bars for the waveform
  const bars = 24
  const getBarHeight = (index: number) => {
    const center = bars / 2
    const distanceFromCenter = Math.abs(index - center)
    const baseHeight = 1 - distanceFromCenter / center
    return baseHeight * intensity * 100
  }

  return (
    <motion.div
      className={cn('flex items-center justify-center gap-[2px] h-16', className)}
      initial={{ opacity: 0 }}
      animate={{ opacity: isActive ? 1 : 0.3 }}
      transition={{ duration: 0.3 }}
    >
      {[...Array(bars)].map((_, i) => (
        <motion.div
          key={i}
          className='w-1 rounded-full'
          style={{ backgroundColor: color }}
          animate={
            isActive
              ? {
                  height: [
                    `${getBarHeight(i) * 0.5}%`,
                    `${getBarHeight(i) * 1.2}%`,
                    `${getBarHeight(i) * 0.8}%`,
                  ],
                  opacity: [0.5, 1, 0.8],
                }
              : {
                  height: '20%',
                  opacity: 0.3,
                }
          }
          transition={{
            duration: 1.5 + Math.random(),
            repeat: Infinity,
            ease: 'easeInOut',
            delay: i * 0.05,
          }}
        />
      ))}

      {/* Glow effect */}
      <motion.div
        className='absolute inset-0 pointer-events-none'
        style={{
          background: `radial-gradient(circle, ${color}20 0%, transparent 70%)`,
        }}
        animate={
          isActive
            ? {
                opacity: [0.3, 0.6, 0.3],
              }
            : {
                opacity: 0,
              }
        }
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: 'easeInOut',
        }}
      />
    </motion.div>
  )
}
