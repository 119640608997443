import React, { useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useRefillProcess } from './use-cases'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'
import type { RefillDeliveryMethod } from '@/domain/retail/refills/refill-types'

export const RefillConfirmationPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const productId = searchParams.get('productId') || ''
  const method = searchParams.get('method') as RefillDeliveryMethod
  const subscription = searchParams.get('subscription') === 'true'
  const cost = Number(searchParams.get('cost') || 0)

  const { loading, error, initiate } = useRefillProcess()
  const navigate = useNavigate()
  const [confirmed, setConfirmed] = useState(false)

  async function confirmRefill() {
    try {
      setConfirmed(true)
      await initiate(productId, method, subscription, cost)
      navigate(
        `/refill/success?productId=${productId}&method=${method}&subscription=${subscription}&cost=${cost}`
      )
    } catch (err) {
      setConfirmed(false)
    }
  }

  if (loading || confirmed) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-background-secondary'>
          <p className='text-center text-text-secondary'>Processing your refill request...</p>
        </Card>
      </div>
    )
  }

  if (error) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-background-secondary'>
          <h2 className='text-2xl font-bold mb-4 text-text-primary'>Error</h2>
          <p className='text-status-error mb-6'>{error}</p>
          <Button
            className='w-full bg-brand-primary text-text-primary'
            onClick={() => navigate('/refill')}
          >
            Try Again
          </Button>
        </Card>
      </div>
    )
  }

  return (
    <div className='max-w-md mx-auto px-4 py-8'>
      <Card className='p-6 bg-background-secondary'>
        <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-white'>Confirm Refill</h2>

        <div className='space-y-4 mb-6'>
          <div className='bg-gray-50 dark:bg-gray-800 p-4 rounded-lg border border-gray-200 dark:border-gray-700'>
            <h3 className='text-lg font-semibold mb-3 text-gray-900 dark:text-white'>
              Order Summary
            </h3>

            <div className='space-y-2'>
              <div className='flex justify-between items-center'>
                <span className='text-gray-700 dark:text-gray-300'>Delivery Method:</span>
                <span className='font-semibold text-gray-900 dark:text-white'>
                  {method === 'inStore' ? 'In-Store Pickup' : 'Home Delivery'}
                </span>
              </div>

              <div className='flex justify-between items-center'>
                <span className='text-gray-700 dark:text-gray-300'>Subscription:</span>
                <span className='font-semibold text-gray-900 dark:text-white'>
                  {subscription ? 'Yes' : 'No'}
                </span>
              </div>

              <div className='flex justify-between items-center pt-2 border-t border-gray-200 dark:border-gray-700'>
                <span className='text-gray-700 dark:text-gray-300'>Total Cost:</span>
                <span className='font-bold text-gray-900 dark:text-white'>${cost.toFixed(2)}</span>
              </div>
            </div>
          </div>
        </div>

        <div className='space-y-4'>
          <Button
            className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            onClick={confirmRefill}
          >
            Confirm Refill
          </Button>
          <Button
            className='w-full border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700'
            variant='outline'
            onClick={() => navigate('/refill')}
          >
            Cancel
          </Button>
        </div>
      </Card>
    </div>
  )
}
