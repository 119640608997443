import type { CustomerProfile } from '../types/customer-profile'

export const mockCustomerProfile: CustomerProfile = {
  id: 'demo-user',
  name: 'Demo User',
  email: 'demo@example.com',
  membershipStatus: 'standard',
  visitCount: 1,
  hasItemInHand: false,
  currentTime: new Date(),
  deviceType: 'desktop',
  preferences: {
    timeOfDay: 'day',
    categories: [],
    sustainabilityFocus: false,
  },
}
