import { EventMap, SystemEvent } from '../types/events'

export interface EventPayloadMap {
  [key: string]: unknown
}

export class EventEmitter<T extends EventPayloadMap = EventPayloadMap> {
  private listeners: Map<keyof T, Set<(payload?: T[keyof T]) => void>> = new Map()

  emit<K extends keyof T>(type: K, payload?: T[K]): void {
    const handlers = this.listeners.get(type)
    if (handlers) {
      handlers.forEach(handler => handler(payload))
    }
  }

  on<K extends keyof T>(type: K, handler: (payload?: T[K]) => void): () => void {
    let handlers = this.listeners.get(type)
    if (!handlers) {
      handlers = new Set()
      this.listeners.set(type, handlers)
    }
    handlers.add(handler as (payload?: T[keyof T]) => void)

    return () => {
      const handlers = this.listeners.get(type)
      if (handlers) {
        handlers.delete(handler as (payload?: T[keyof T]) => void)
      }
    }
  }

  off<K extends keyof T>(type: K, handler: (payload?: T[K]) => void): void {
    const handlers = this.listeners.get(type)
    if (handlers) {
      handlers.delete(handler as (payload?: T[keyof T]) => void)
    }
  }
}

export class TypedEventEmitter<T extends EventMap> {
  private listeners: Map<keyof T, Set<(event: SystemEvent<keyof EventMap>) => void>> = new Map()

  emit<K extends keyof T>(type: K, payload: T[K]): void {
    const systemEvent: SystemEvent<keyof EventMap> = {
      id: crypto.randomUUID(),
      type,
      timestamp: Date.now(),
      payload,
    }

    const handlers = this.listeners.get(type)
    if (handlers) {
      handlers.forEach(handler => {
        handler(systemEvent)
      })
    }
  }

  on<K extends keyof T>(type: K, handler: (event: SystemEvent<keyof EventMap>) => void): () => void {
    let handlers = this.listeners.get(type)
    if (!handlers) {
      handlers = new Set()
      this.listeners.set(type, handlers)
    }
    handlers.add(handler)

    return () => {
      const handlers = this.listeners.get(type)
      if (handlers) {
        handlers.delete(handler)
      }
    }
  }

  off<K extends keyof T>(type: K, handler: (event: SystemEvent<keyof EventMap>) => void): void {
    const handlers = this.listeners.get(type)
    if (handlers) {
      handlers.delete(handler)
    }
  }
}

// Create and export a singleton instance of EventEmitter
export const eventEmitter = new EventEmitter()
