import { eventBus } from '@/domain/events/event-bus'
import { generateId } from '@/shared/utils/id-generator'
import { products } from '@/config/brands'
import type { ReturnRequest, ReturnMethod, ReturnAssessmentResult } from './return-types'

// Add return events to EventMap
declare module '@/domain/events/types' {
  interface EventMap {
    RETURN_ASSESSED: ReturnAssessmentResult
    RETURN_INITIATED: {
      requestId: string
      productId: string
      returnMethod: ReturnMethod
    }
    RETURN_COMPLETED: {
      requestId: string
      status: 'completed'
    }
  }
}

class ReturnService {
  private requests: ReturnRequest[] = []

  async assessReturn(productId: string): Promise<ReturnAssessmentResult> {
    console.log('Assessing return for product:', productId)
    const product = products.find(p => p.id === productId)

    if (!product) {
      console.error('Product not found:', productId)
      throw new Error(`Product not found: ${productId}`)
    }

    if (!product.returnEligible) {
      return {
        productId,
        isEligible: false,
        storeCreditValue: 0,
        pointsValue: 0,
        message: 'This product is not eligible for return.',
      }
    }

    // Calculate return values based on product price
    const storeCreditValue = Math.round(product.price * 0.7 * 100) / 100 // 70% of original price
    const pointsValue = Math.round(product.price * 100) // 100 points per dollar

    const result: ReturnAssessmentResult = {
      productId,
      isEligible: true,
      storeCreditValue,
      pointsValue,
      message: 'Product is eligible for return.',
    }

    eventBus.emit({
      id: generateId(),
      type: 'RETURN_ASSESSED',
      timestamp: Date.now(),
      payload: result,
    })

    return result
  }

  async initiateReturn(productId: string, returnMethod: ReturnMethod): Promise<ReturnRequest> {
    const request: ReturnRequest = {
      id: generateId(),
      productId,
      returnMethod,
      status: 'pending',
      createdAt: new Date(),
    }

    this.requests.push(request)

    eventBus.emit({
      id: generateId(),
      type: 'RETURN_INITIATED',
      timestamp: Date.now(),
      payload: {
        requestId: request.id,
        productId,
        returnMethod,
      },
    })

    return request
  }

  async completeReturn(requestId: string): Promise<ReturnRequest> {
    const request = this.requests.find(r => r.id === requestId)
    if (!request) {
      throw new Error('Return request not found')
    }

    request.status = 'completed'
    request.completedAt = new Date()

    eventBus.emit({
      id: generateId(),
      type: 'RETURN_COMPLETED',
      timestamp: Date.now(),
      payload: {
        requestId,
        status: 'completed' as const,
      },
    })

    return request
  }

  getRequest(requestId: string): ReturnRequest | undefined {
    return this.requests.find(r => r.id === requestId)
  }

  listRequests(): ReturnRequest[] {
    return [...this.requests]
  }
}

export const returnService = new ReturnService()
