import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@/shared/components/ui/button'
import { Card } from '@/shared/components/ui/card'
import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'
import { Help, Return } from '@/assets/icons/Icons'
import scanBackground from '@/assets/background/scan-background.jpg'
import OnIcon from '@/assets/logos/onIcon.svg'

export default function RepairScanPage() {
  const navigate = useNavigate()

  React.useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/repair/assess')
    }, 4000)

    return () => clearTimeout(timer)
  }, [navigate])

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background */}
      <img
        src={scanBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />

      <div className='fixed inset-0 -z-10 overflow-hidden'>
        <div className='absolute inset-0'>
          <div
            className='absolute w-full h-screen border-t-4 border-white '
            style={{
              background: 'linear-gradient(to bottom, #ffffff80 0%, transparent 100%)',
              animation: 'scanMove 2s linear infinite',
            }}
          />
        </div>
      </div>
      <style>
        {`
          @keyframes scanMove {
            from {
              transform: translateY(100%);
            }
            to {
              transform: translateY(-100%);
            }
          }
        `}
      </style>

      {/* Content */}
      <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-between'>
        <div className='flex items-center gap-4'>
          <img
            src={OnIcon}
            alt='ON Logo'
            className='w-[48px] h-[48px]'
            onClick={() => navigate('/')}
          />
          <div className='flex flex-col h-full'>
            <div className='text-xl font-medium'>Cloudmonster</div>
            <div className='text-sm font-light'>Scanning in progress...</div>
          </div>
        </div>
        <KioskButton>
          <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
          <div className='text-lg font-light'>Hey, Phil</div>
        </KioskButton>
      </div>

      <div className='fixed z-20 bottom-0 left-0 w-screen flex justify-between p-8'>
        <KioskButton className='px-6' onClick={() => navigate('/return')}>
          <Return className='w-5 h-5' />
        </KioskButton>
        <KioskButton>
          <Help className='w-8 h-8' />
        </KioskButton>
      </div>
    </div>
  )
}
