import { useState, useEffect } from 'react'
import {
  assessRepair,
  startRepair,
  updateRepairStatus,
  getRepairRequest,
  getRepairHistory,
  calculateRepairProgress,
  getRepairCostBreakdown,
} from '@/domain/retail/repairs/repair-use-cases'
import type {
  RepairAssessmentResult,
  RepairItem,
  RepairRequest,
  RepairHistory,
} from '@/domain/retail/repairs/repair-types'

export function useRepairAssessment() {
  const [assessment, setAssessment] = useState<RepairAssessmentResult | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function assessItem(productId: string) {
    try {
      setLoading(true)
      setError(null)
      const result = assessRepair(productId)
      setAssessment(result)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to assess item')
    } finally {
      setLoading(false)
    }
  }

  return {
    assessment,
    loading,
    error,
    assessItem,
  }
}

export function useRepairProcess() {
  const [request, setRequest] = useState<RepairRequest | null>(null)
  const [history, setHistory] = useState<RepairHistory | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function initiateRepair(item: RepairItem, customerNotes?: string) {
    try {
      setLoading(true)
      setError(null)
      const result = startRepair(item, customerNotes)
      setRequest(result)
      return result
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to start repair')
      return null
    } finally {
      setLoading(false)
    }
  }

  async function updateStatus(requestId: string, status: RepairRequest['status'], notes?: string) {
    try {
      setLoading(true)
      setError(null)
      const result = updateRepairStatus(requestId, status, notes)
      setRequest(result)
      return result
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update repair status')
      return null
    } finally {
      setLoading(false)
    }
  }

  function loadRepairDetails(requestId: string) {
    try {
      setLoading(true)
      setError(null)
      const repairRequest = getRepairRequest(requestId)
      const repairHistory = getRepairHistory(requestId)
      if (repairRequest) {
        setRequest(repairRequest)
        setHistory(repairHistory || null)
      } else {
        setError('Repair request not found')
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load repair details')
    } finally {
      setLoading(false)
    }
  }

  return {
    request,
    history,
    loading,
    error,
    initiateRepair,
    updateStatus,
    loadRepairDetails,
  }
}

export function useRepairProgress(requestId: string | null) {
  const [progress, setProgress] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!requestId) return

    const updateProgress = () => {
      setLoading(true)
      const currentProgress = calculateRepairProgress(requestId)
      setProgress(currentProgress)
      setLoading(false)
    }

    updateProgress()
    const interval = setInterval(updateProgress, 30000) // Update every 30 seconds

    return () => clearInterval(interval)
  }, [requestId])

  return { progress, loading }
}

export function useRepairCosts(item: RepairItem | null) {
  const [costs, setCosts] = useState<ReturnType<typeof getRepairCostBreakdown> | null>(null)

  useEffect(() => {
    if (item) {
      setCosts(getRepairCostBreakdown(item))
    } else {
      setCosts(null)
    }
  }, [item])

  return costs
}
