import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useRepairAssessment, useRepairCosts } from './use-cases'
import { Card } from '@/shared/components/ui/card'

import kioskBackground from '@/assets/background/kiosk-background.jpg'
import OnLogo from '@/assets/logos/onLogo.svg'
import UserImage from '@/assets/user/userPicture.png'
import KioskButton from '@/components/Kiosk/KioskButton'

import OnIcon from '@/assets/logos/onIcon.svg'
import { KioskCard } from '@/components/Kiosk/KioskCard'
import { InlineCheck } from '@/assets/icons/Icons'
import OnSpinner from '@/assets/animated/OnSpinner'

export const RepairAssessmentPage: React.FC = () => {
  const { assessment, loading, error, assessItem } = useRepairAssessment()
  const costs = useRepairCosts(assessment?.item || null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [ready, setReady] = useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  const productId = searchParams.get('productId')

  useEffect(() => {
    if (productId) {
      assessItem(productId)
    }
  }, [productId])

  return (
    <div className='min-h-screen pt-[120px] lg:pt-0 flex flex-col justify-center items-center gap-4 w-fit mx-auto pb-[120px] sm:pb-[80px] px-4'>
      {/* Background Strip covering the bottom 10% of the page leave space for voice controls */}
      <div className='fixed bottom-0 left-0 right-0 h-[160px] z-10 from-[#080e20] via-[#080e20] to-transparent bg-gradient-to-t pointer-events-none'></div>
      {/* Background */}
      <img
        src={kioskBackground}
        alt='Kiosk Background'
        className='w-screen h-screen object-cover fixed top-0 -z-10 origin-bottom'
      />
      {/* Content */}
      {ready ? (
        <>
          <div className='w-full absolute py-8 top-0 px-8 left-0 flex justify-between'>
            <div className='flex items-center gap-4'>
              <img
                src={OnIcon}
                alt='ON Logo'
                className='w-[48px] h-[48px]'
                onClick={() => navigate('/')}
              />
            </div>
            <KioskButton>
              <img src={UserImage} alt='User Image' className='w-6 h-6 rounded-full -ml-1' />
              <div className='text-lg font-light'>Hey, Phil</div>
            </KioskButton>
          </div>
          {/* <img src={OnLogo} alt='On Logo' className='w-16 h-16 mb-4' /> */}
          <h1 className='text-[2.5rem] font-medium font-manrope text-white'>Repair Assessment</h1>
          <p className='text-lg text-[#3AFFBD] font-light w-full text-center pb-6'>
            Your product is eligible for repair{' '}
          </p>
          <div className='p-6 rounded-xl bg-black flex flex-col sm:flex-row gap-5'>
            <div className='flex flex-col gap-5 min-w-[300px]'>
              <div className='flex flex-col gap-1'>
                <div className='font-light text-text-secondary'>Product</div>
                <div className='font-light'>Cloudmonster • Size 11</div>
              </div>
              <div className='flex flex-col gap-1'>
                <div className='font-light text-text-secondary'>Est. Repair Cost</div>
                <div className='font-light'>$30.00</div>
              </div>
              <div className='flex flex-col gap-1'>
                <div className='font-light text-text-secondary'>Repair Actions</div>
                <div className='font-light flex gap-1 items-center'>
                  <InlineCheck />
                  Clean and sanitize
                </div>
                <div className='font-light flex gap-1 items-center'>
                  <InlineCheck />
                  Replace laces
                </div>
                <div className='font-light flex gap-1 items-center'>
                  <InlineCheck />
                  Replace inner sole
                </div>
              </div>
            </div>
            <div>
              <div className='flex flex-col gap-5 min-w-[300px]'>
                <div className='flex flex-col gap-1'>
                  <div className='font-light text-text-secondary'>Condition</div>
                  <div className='font-light'>Worn</div>
                </div>
                <div className='flex flex-col gap-1'>
                  <div className='font-light text-text-secondary'>Est. Time to Repair</div>
                  <div className='font-light'>3 days</div>
                </div>
              </div>
            </div>
          </div>
          <KioskButton
            className='bg-yellow-500 text-black border-none py-3 px-6 hover:bg-yellow-400 gap-2 mt-6'
            onClick={() => navigate('/repair/confirm')}
          >
            Confirm Repair<span className='font-light'>$30.00</span>
          </KioskButton>
        </>
      ) : (
        <OnSpinner className='w-[200px] h-[200px]' />
      )}
    </div>
  )
  // <div className='max-w-2xl mx-auto px-4 py-8'>
  //   <h1 className='text-3xl font-bold text-center mb-8 text-gray-900 dark:text-gray-50'>
  //     Repair Assessment
  //   </h1>

  //   <Card className='mb-6 p-6'>
  //     <h2 className='text-xl font-semibold mb-4 text-gray-900 dark:text-gray-50'>
  //       Assessment Result
  //     </h2>
  //     <div className='space-y-6'>
  //       <div className='grid grid-cols-2 gap-4 text-sm'>
  //         <div>
  //           <p className='text-gray-700 dark:text-gray-300'>Item Type</p>
  //           <p className='font-medium text-gray-900 dark:text-gray-50'>{item.type}</p>
  //         </div>
  //         <div>
  //           <p className='text-gray-700 dark:text-gray-300'>Condition</p>
  //           <p className='font-medium text-gray-900 dark:text-gray-50'>{item.condition}</p>
  //         </div>
  //         <div>
  //           <p className='text-gray-700 dark:text-gray-300'>Repair Cost</p>
  //           <p className='font-medium text-gray-900 dark:text-gray-50'>
  //             ${item.repairCost.toFixed(2)}
  //           </p>
  //         </div>
  //         <div>
  //           <p className='text-gray-700 dark:text-gray-300'>Estimated Time</p>
  //           <p className='font-medium text-gray-900 dark:text-gray-50'>
  //             {item.estimatedTimeDays} days
  //           </p>
  //         </div>
  //       </div>

  //       {suggestedActions && suggestedActions.length > 0 && (
  //         <div>
  //           <h3 className='font-semibold mb-2 text-gray-900 dark:text-gray-50'>Repair Actions</h3>
  //           <ul className='list-disc list-inside space-y-1 text-sm text-gray-700 dark:text-gray-300'>
  //             {suggestedActions.map((action, index) => (
  //               <li key={index}>{action}</li>
  //             ))}
  //           </ul>
  //         </div>
  //       )}
  //     </div>
  //   </Card>

  //   <div className='space-y-3'>
  //     <button
  //       onClick={() => navigate(`/repair/confirm?productId=${item.productId}`)}
  //       className='w-full py-3 px-4 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-semibold'
  //     >
  //       Proceed with Repair (${item.repairCost.toFixed(2)})
  //     </button>
  //     <button
  //       onClick={() => navigate('/')}
  //       className='w-full py-3 px-4 rounded-lg border border-gray-300 dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-700 dark:text-gray-300 font-semibold'
  //     >
  //       Cancel
  //     </button>
  //   </div>
  // </div>
}
