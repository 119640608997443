import React, { useEffect } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useRefillAssessment } from './use-cases'
import { Card } from '@/shared/components/ui/card'
import { Button } from '@/shared/components/ui/button'

export const RefillAssessmentPage: React.FC = () => {
  const { assessment, loading, error, assessContainer } = useRefillAssessment()
  const [searchParams] = useSearchParams()
  const containerId = searchParams.get('containerId') || ''
  const navigate = useNavigate()

  useEffect(() => {
    if (containerId) {
      assessContainer(containerId)
    }
  }, [containerId])

  if (loading) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <p className='text-center text-gray-600 dark:text-gray-300'>Assessing container...</p>
        </Card>
      </div>
    )
  }

  if (error) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-50'>Error</h2>
          <p className='text-red-600 dark:text-red-400 mb-6'>{error}</p>
          <Button
            className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            onClick={() => navigate('/refill')}
          >
            Try Again
          </Button>
        </Card>
      </div>
    )
  }

  if (!assessment?.isEligible) {
    return (
      <div className='max-w-md mx-auto px-4 py-8'>
        <Card className='p-6 bg-white dark:bg-gray-800'>
          <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-50'>
            Refill Assessment
          </h2>
          <p className='text-gray-700 dark:text-gray-300 mb-6'>
            {assessment?.message || 'Container is not eligible for refill.'}
          </p>
          <Button
            className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            onClick={() => navigate('/refill')}
          >
            Try Another Container
          </Button>
        </Card>
      </div>
    )
  }

  return (
    <div className='max-w-md mx-auto px-4 py-8'>
      <Card className='p-6 bg-white dark:bg-gray-800'>
        <h2 className='text-2xl font-bold mb-4 text-gray-900 dark:text-gray-50'>
          Refill Assessment
        </h2>
        <p className='text-gray-700 dark:text-gray-300 mb-6'>{assessment.message}</p>

        <div className='space-y-4 mb-6 bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg border border-gray-200 dark:border-gray-600'>
          <div className='flex justify-between items-center'>
            <span className='text-gray-600 dark:text-gray-300'>Refill Cost:</span>
            <span className='font-semibold text-gray-900 dark:text-gray-50'>
              ${assessment.refillCost.toFixed(2)}
            </span>
          </div>
          <div className='flex justify-between items-center'>
            <span className='text-gray-600 dark:text-gray-300'>Estimated Time:</span>
            <span className='font-semibold text-gray-900 dark:text-gray-50'>
              {assessment.estimatedTimeDays} days
            </span>
          </div>
        </div>

        <div className='space-y-4'>
          <Button
            className='w-full bg-emerald-600 hover:bg-emerald-700 text-white dark:bg-emerald-700 dark:hover:bg-emerald-600'
            onClick={() =>
              navigate(
                `/refill/options?productId=${assessment.productId}&cost=${assessment.refillCost}`
              )
            }
          >
            Choose Refill Options
          </Button>
          <Button
            className='w-full border border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-700'
            variant='outline'
            onClick={() => navigate('/refill')}
          >
            Cancel
          </Button>
        </div>
      </Card>
    </div>
  )
}
